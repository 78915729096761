@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px); }
  to {
    transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    -moz-transform: translate(0, 100px);
    -ms-transform: translate(0, 100px);
    -o-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -ms-transition: -ms-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    transform: rotate(45deg); }
  .modal-video-close-btn:after {
    transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
@media (min-width: 768px) {
  .navbar-header {
    float: none; }
  a.navbar-brand {
    width: 100%; }
  a.navbar-brand .default-logo {
    margin: auto; }
  .fintupHeader {
    margin-bottom: 20px; } }

.default-logo {
  height: 45px;
  cursor: pointer; }

.nav.navbar {
  margin-bottom: 5px; }

.nav .navbar-brand {
  padding-top: 10px; }

.navbar-toggle[aria-expanded="false"] .closeMenuIcon {
  display: none; }

.navbar-toggle[aria-expanded="true"] .icon-bar {
  display: none; }

.navbar-right {
  margin-right: 25px;
  margin-top: -30px; }

.navbar-default {
  background-color: #ffffff;
  border-color: #ffffff; }

.navbar-nav > li {
  margin-left: 15px; }

.navbar .divider-vertical {
  height: 50px;
  margin: 0 9px;
  border-right: 1px solid #ffffff;
  border-left: 1px solid #f2f2f2; }

.nav .navbar-toggle {
  width: 45px;
  float: left;
  margin-left: 15px;
  margin-right: 0px;
  border-radius: 20px; }

.fintup .navbar-default .navbar-nav > li > a:focus {
  outline: none; }

.nav .notificationBadge {
  position: absolute;
  z-index: 100;
  font-size: 10px;
  background-color: #FF0000;
  padding-bottom: 2px;
  padding-top: 2px;
  padding-right: 4px;
  padding-left: 4px;
  left: 30px;
  top: 0px; }

@media (max-width: 767px) {
  .navbar-collapse .nav > .divider-vertical {
    display: none; }
  .fintupHeader .navbar-default {
    background-color: #00748C;
    padding-top: 10px;
    padding-bottom: 10px; }
  .nav .navbar-brand {
    padding-left: 0px;
    margin-left: 0px;
    float: none; }
  .nav.navbar {
    margin-bottom: 0px; }
  .nav .brandContainer {
    text-align: center; }
  .nav .navbar-brand > img {
    display: inherit; }
  .nav .navbar-collapse {
    background-color: #191919; } }
