@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px); }
  to {
    transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    -moz-transform: translate(0, 100px);
    -ms-transform: translate(0, 100px);
    -o-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -ms-transition: -ms-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    transform: rotate(45deg); }
  .modal-video-close-btn:after {
    transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.rtf {
  z-index: 1001 !important;
  height: 75px !important;
  width: 75px !important;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (max-width: 767px) {
    .rtf {
      height: 50px !important;
      width: 50px !important;
      margin-bottom: 0px !important;
      justify-content: flex-end; } }

.rtf--mb > * {
  transform: none !important;
  transition: none !important; }

.rtf--mb__c {
  padding-top: 0px !important; }
  @media (max-width: 767px) {
    .rtf--mb__c {
      padding-right: 0px !important;
      padding-bottom: 0px !important; } }

.fintupFabExpert {
  margin-bottom: -25px !important;
  background: #FFFFFF;
  z-index: 1001;
  max-width: 320px;
  position: fixed;
  bottom: 0px;
  right: 5px;
  color: #000000;
  padding: 25px;
  font-size: 13px;
  text-align: justify;
  letter-spacing: 0.4px;
  border-radius: 10px;
  border: 2px solid #52d2bc;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05); }
  @media (max-width: 767px) {
    .fintupFabExpert {
      right: -25px !important; } }
  .fintupFabExpert h1 {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px; }
  .fintupFabExpert .managerImage {
    transition: none !important; }
  .fintupFabExpert .container-options .mainImage {
    width: 50px !important;
    height: 50px !important; }
  .fintupFabExpert .container-options .header-options {
    display: flex;
    align-items: center; }
    .fintupFabExpert .container-options .header-options h3 {
      font-size: 16px;
      margin-left: 10px; }
  .fintupFabExpert .container-options .phrase {
    margin-top: 20px;
    font-weight: normal;
    font-style: italic;
    padding-bottom: 20px;
    border-bottom: 1px dotted #979797;
    white-space: pre-wrap; }
  .fintupFabExpert .container-options h3 {
    margin: 20px 0px 10px;
    font-size: 16px;
    font-weight: bold; }
  .fintupFabExpert .container-options table td {
    line-height: 35px; }
    .fintupFabExpert .container-options table td a {
      cursor: pointer;
      font-weight: normal;
      color: #000000; }
    .fintupFabExpert .container-options table td a.element {
      color: #52d2bc;
      margin-top: 3px;
      display: block; }
  .fintupFabExpert .container-options table tr:first-child a.element {
    font-weight: bold;
    font-size: 16px; }
  .fintupFabExpert .container-options table td:first-child {
    text-align: center;
    padding-right: 5px; }
