@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px); }
  to {
    transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    -moz-transform: translate(0, 100px);
    -ms-transform: translate(0, 100px);
    -o-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -ms-transition: -ms-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    transform: rotate(45deg); }
  .modal-video-close-btn:after {
    transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.aviso-sego {
  border: 2px solid #E19805;
  background-color: #FFFAF0;
  padding: 16px;
  font-size: 14px;
  margin: 0 auto;
  margin-bottom: 20px; }
  .aviso-sego .material-symbols-outlined {
    color: #F9A806;
    font-size: 36px;
    width: 36px;
    height: 36px; }
  .aviso-sego a {
    color: black !important; }

.no-padding-izquierda {
  padding-left: 0px; }

.cnp-app .home .leftContainer {
  display: none; }

.cnp-app .home .registerTextContainer {
  display: block;
  float: left;
  text-align: left;
  color: #00748C;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 14px;
  cursor: pointer; }

@media (min-width: 992px) {
  .cnp-app .home .rightContainer {
    left: 25%; } }

@media (min-width: 768px) {
  .cnp-app .home .rightContainer {
    width: 58.33333333%; }
    .cnp-app .home .rightContainer .loginButtonContainer {
      width: 100%;
      padding-left: 0px;
      padding-right: 0px; } }

@media (max-width: 767px) {
  .cnp-app .home .forgetContainer {
    text-align: center;
    margin-top: 15px;
    float: none; }
  .cnp-app .home .registerTextContainer {
    text-align: center;
    margin-top: 15px;
    float: none; } }

.homeView {
  /*display: flex;
  justify-content: center;
  align-items: center;*/
  padding: 30px 30px 0px 30px;
  min-height: calc(100vh - 130px); }
  .homeView .home .registerDot {
    margin-left: 75px; }
    .homeView .home .registerDot img {
      margin-right: 15px; }
  .homeView .home .leftContainer h1 {
    font-size: 30px;
    font-weight: bold; }
  .homeView .home .leftContainer img {
    margin: auto;
    margin-top: 30px;
    display: block;
    height: 300px;
    width: 400px; }
  .homeView .home .separator {
    float: left;
    width: 40%; }
    .homeView .home .separator hr {
      margin-top: 10px; }
  .homeView .home .separatorCenter {
    float: left;
    width: 20%;
    text-align: center;
    color: #00748C; }
  .homeView .home h2 {
    margin-top: 30px;
    font-weight: bold;
    font-size: 20px; }
  .homeView .home .inputTextLabel {
    margin-top: 20px; }
    .homeView .home .inputTextLabel .form-group {
      margin-bottom: 0px; }
  .homeView .home .loginButtonContainer {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    padding-right: 0px; }
  .homeView .home .forgetContainer {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
    text-align: right;
    color: #000000;
    margin-bottom: 5px;
    font-size: 14px;
    cursor: pointer; }
  .homeView .home .registerTextContainer {
    display: none; }

@media (min-width: 767px) {
  .cnp-app .home .form-container {
    min-height: 380px; } }

@media (max-width: 767px) {
  .home {
    padding-bottom: 50px; }
    .home .registerDot {
      margin-left: 0px; }
    .home .separator {
      float: left;
      width: 0%; }
    .home .separatorCenter {
      float: left;
      width: 100%;
      text-align: center;
      color: #00748C; } }
