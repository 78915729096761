@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px); }
  to {
    transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    -moz-transform: translate(0, 100px);
    -ms-transform: translate(0, 100px);
    -o-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -ms-transition: -ms-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    transform: rotate(45deg); }
  .modal-video-close-btn:after {
    transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.contractTabs {
  margin-top: 20px; }
  .contractTabs .title h1 {
    margin: 0px;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 30px; }
  .contractTabs img {
    width: 210px;
    height: 125px; }
  .contractTabs h4 {
    color: #00748C;
    font-size: 20px;
    font-weight: bold; }
  .contractTabs p {
    font-size: 14px;
    line-height: 1.67;
    letter-spacing: 0.32px; }
  .contractTabs .previous {
    cursor: pointer; }
  .contractTabs .breadcrumbBlock {
    margin-top: 30px;
    margin-bottom: 30px;
    overflow: hidden; }
    .contractTabs .breadcrumbBlock .crumb {
      width: 150px;
      /*
      * Mobile
      */
      height: 40px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 33px; }
      @media (max-width: 767px) {
        .contractTabs .breadcrumbBlock .crumb {
          width: 65px; } }
      @media (max-width: 767px) {
        .contractTabs .breadcrumbBlock .crumb {
          font-size: 8px; } }
      .contractTabs .breadcrumbBlock .crumb .icon-wrapper .fa {
        font-size: 18px; }
        @media (max-width: 767px) {
          .contractTabs .breadcrumbBlock .crumb .icon-wrapper .fa {
            font-size: 11px; } }
      .contractTabs .breadcrumbBlock .crumb .icon-wrapper, .contractTabs .breadcrumbBlock .crumb .fintupIcons {
        margin-left: 10px;
        font-weight: 300;
        /*
        * Mobile
        */ }
        @media (max-width: 767px) {
          .contractTabs .breadcrumbBlock .crumb .icon-wrapper, .contractTabs .breadcrumbBlock .crumb .fintupIcons {
            margin-left: 2px;
            font-size: 9px !important; } }
    .contractTabs .breadcrumbBlock .bread:after {
      position: absolute;
      content: "";
      height: 2px;
      width: calc(100% - 150px);
      margin-left: calc(150px / 2);
      top: 50%;
      z-index: 1;
      /*
        * Mobile
        */ }
      @media (max-width: 767px) {
        .contractTabs .breadcrumbBlock .bread:after {
          width: calc(100% - 65px);
          margin-left: calc((65px + 2px) / 2); } }
    .contractTabs .breadcrumbBlock .bread.previous:after {
      background: #7FB9C5; }
    .contractTabs .breadcrumbBlock .bread.previous .crumb {
      background-color: #FFFFFF;
      border: 1px solid #7FB9C5;
      color: #7FB9C5; }
    .contractTabs .breadcrumbBlock .bread.active:after {
      background: #e6e6e6; }
    .contractTabs .breadcrumbBlock .bread.active .crumb {
      background-color: #FF5200;
      border: 1px solid #FF5200;
      color: #FFFFFF;
      font-weight: bold; }
    .contractTabs .breadcrumbBlock .bread.next:after {
      background: #e6e6e6; }
    .contractTabs .breadcrumbBlock .bread.next .crumb {
      background-color: #FFFFFF;
      margin: auto;
      border: 1px solid #e6e6e6;
      color: #e6e6e6; }
    .contractTabs .breadcrumbBlock .bread:last-child:after {
      background: transparent; }
