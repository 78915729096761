@import 'node_modules/react-modal-video/scss/modal-video.scss';

$dark: #00748C;
$dark-color-font: #FFFFFF;
$medium: #3F96A8;
$medium-color-font: $dark-color-font;
$light: #7FB9C5;
$light-color-font: $dark-color-font;
$info: #009CA6;
$info-color-font: $dark-color-font;
$cancel: #FB4D6D;
$action: #52d2bc;
$action-color-font: #FFFFFF;
$disclaimerColor: #4a4a4a;
$disableColor: #b5b5b5;
$result: #bfdce2;
$result-color-font: #000000;
$result-button-color-font: $dark;
$errorInput: #f2dede;
$grey-background: #efefef;
$grey-font: #979797;
$border-grey-color: #E5E5E5;
$background-app: #FFFFFF;
$conmmutador-grey: #8a8a8a;
$background-landing-minitest: #DFEDF0;
$background-element-minitest: #EFFCFF;
$color-cross-element-minitest: #EF6776;
$color-href-modal: #428bca;
$border-element: #ddd;
$background-element: #F2F2F2;
$background-element-hover: #D2D2D2;

$font-size-xxsmall: 8px;
$font-size-xsmall: 10px;
$font-size-small: 11px;
$font-size-normal: 13px;
$font-size-medium: 15px;
$font-size-large: 18px;
$font-size-big: 20px;
$font-size-extrabig: 25px;
$font-size-xextrabig: 30px;
$font-size-xxlextrabig: 40px;
$font-size-xxxlextrabig: 50px;
$font-size-xxextrabig: 55px;
$font-size-xxxextrabig: 72px;

$font-size-mobile-xsmall: 9px;//7px;
$font-size-mobile-small: 13px;//11px;
$font-size-mobile-medium: 12px;//10px;
$font-size-mobile-normal: 14px;//12px;
$font-size-mobile-normal2: 16px;//14px;
$font-size-mobile-large: 18px;//16px;
$font-size-mobile-big: 17px;//15px;
$font-size-mobile-extrabig: 21px;
$font-size-mobile-doubleextrabig: 24px;
$font-size-mobile-tripleextrabig: 36px;

$mobile-header-size: 115px;

/*
* New styles
* Minitest
*/
$shadow-color-default: #e6e6e6;
$shadow-color-slider-default: #52d2bc5e;
$background-color-default-button-icon-selectable: #52d2bc1f;
$background-color-default-select-option-selected: #ebf5ff;
$background-color-default-select-option: #007eff0a;
$color-placeholder: #52d2bc5f;
$color-attention: #FF5200;
$color-action-button: #4285f4;
