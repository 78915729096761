@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px); }
  to {
    transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    -moz-transform: translate(0, 100px);
    -ms-transform: translate(0, 100px);
    -o-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -ms-transition: -ms-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    transform: rotate(45deg); }
  .modal-video-close-btn:after {
    transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px); }
  to {
    transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    -moz-transform: translate(0, 100px);
    -ms-transform: translate(0, 100px);
    -o-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -ms-transition: -ms-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    transform: rotate(45deg); }
  .modal-video-close-btn:after {
    transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px); }
  to {
    transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    -moz-transform: translate(0, 100px);
    -ms-transform: translate(0, 100px);
    -o-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -ms-transition: -ms-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    transform: rotate(45deg); }
  .modal-video-close-btn:after {
    transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
/**
* New styles of components
*/
*.theme-new {
  font-family: 'Lato' !important;
  font-size: 18px !important; }
  *.theme-new label, *.theme-new .form-control {
    font-family: 'Lato' !important;
    font-size: 18px !important; }
  *.theme-new .react-date-picker__wrapper {
    font-family: 'Lato';
    border: none;
    border-bottom: 2px solid #52d2bc;
    box-shadow: none;
    min-width: 180px;
    margin-bottom: 15px; }
    *.theme-new .react-date-picker__wrapper .react-date-picker__inputGroup {
      display: flex;
      justify-content: center;
      align-items: center; }
    *.theme-new .react-date-picker__wrapper input::placeholder, *.theme-new .react-date-picker__wrapper input::-webkit-input-placeholder {
      font-weight: 400;
      color: #52d2bc5f; }
    *.theme-new .react-date-picker__wrapper .react-date-picker__inputGroup__divider {
      font-size: 30px;
      font-weight: 500;
      color: #52d2bc;
      padding: 0px 10px; }

.inputTextLabel {
  /* enable absolute positioning */
  /* align icon */ }
  .inputTextLabel.underLineControl .form-group.row {
    margin-right: 0px;
    margin-left: 0px; }
    .inputTextLabel.underLineControl .form-group.row label {
      font-weight: normal;
      color: #00748C;
      margin-top: 10px; }
  .inputTextLabel.underLineControl .form-control {
    border-top-width: 0px;
    border-top-style: solid;
    border-left-width: 0px;
    border-left-style: none;
    border-right-width: 0px;
    border-right-style: none;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #00748C;
    -webkit-box-shadow: none;
    box-shadow: none;
    transition: none; }
    .inputTextLabel.underLineControl .form-control[readonly] {
      background-color: transparent; }
    .inputTextLabel.underLineControl .form-control:focus {
      border-color: none;
      outline: 0;
      box-shadow: none; }
  .inputTextLabel.theme-new {
    letter-spacing: normal; }
    .inputTextLabel.theme-new .form-group.row {
      margin-right: 0px;
      margin-left: 0px; }
      .inputTextLabel.theme-new .form-group.row label {
        font-weight: normal;
        color: #00748C;
        margin-top: 10px; }
    .inputTextLabel.theme-new .inputLegendLink {
      font-size: 13px; }
    .inputTextLabel.theme-new .form-control {
      padding-left: 0px;
      padding-bottom: 0px;
      border: 0px;
      box-shadow: none;
      transition: none;
      border-bottom: 2px solid #52d2bc;
      height: auto;
      color: #000000; }
      .inputTextLabel.theme-new .form-control::placeholder, .inputTextLabel.theme-new .form-control::-webkit-input-placeholder {
        font-weight: 400;
        color: #979797;
        font-size: 16px; }
      .inputTextLabel.theme-new .form-control[readonly] {
        background-color: #efefef; }
      .inputTextLabel.theme-new .form-control:focus {
        border-color: #52d2bc;
        outline: 0;
        box-shadow: none; }
    .inputTextLabel.theme-new.is-error .form-control {
      border-bottom: 2px solid #FB4D6D;
      /*&::placeholder, &::-webkit-input-placeholder {
          color: $color-cross-element-minitest;
        }*/ }
    .inputTextLabel.theme-new.is-error .errorText {
      color: #FB4D6D;
      font-size: 14px;
      display: block;
      margin-top: 5px;
      text-align: left; }
  .inputTextLabel.full-width {
    width: 100%; }
  .inputTextLabel .required-label {
    color: #FB4D6D;
    font-weight: 700;
    font-size: 14px;
    margin-left: 5px; }
  .inputTextLabel label {
    margin-bottom: 10px; }
  .inputTextLabel .form-group.row label {
    margin-bottom: 5px;
    margin-top: 5px; }
  .inputTextLabel .form-group.row label.rightLabel {
    margin-top: 0px; }
  .inputTextLabel .form-group.row label.rightLabel.dividerLabel {
    border-left-color: silver;
    border-left-width: 1px;
    border-left-style: solid;
    padding-left: 10px; }
  .inputTextLabel label.htmlLabel {
    font-weight: inherit; }
  .inputTextLabel .appendText {
    margin-left: -15px; }
  .inputTextLabel .form-control {
    border-radius: 0px; }
  .inputTextLabel input.form-control::placeholder {
    font-weight: 700; }
  .inputTextLabel .inner-addon {
    position: relative;
    /* style icon */ }
    .inputTextLabel .inner-addon .addonContent {
      position: absolute;
      top: 5px;
      /* pointer-events: none; */ }
  .inputTextLabel .left-addon .addonContent {
    left: 0px; }
  .inputTextLabel .left-addon input {
    padding-left: 50px; }
  .inputTextLabel .right-addon .addonContent {
    right: 0px; }
  .inputTextLabel .right-addon input {
    padding-right: 50px; }
  .inputTextLabel .addonContent .fintupIcons {
    margin-left: 5px;
    cursor: pointer; }
  .inputTextLabel .form-control:focus {
    border-color: #00748C;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #00748C;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #00748C; }
  .inputTextLabel.innerInput .form-control {
    border-color: #00748C;
    outline: 0;
    -webkit-box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075), 0 0 0px #00748C;
    box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075), 0 0 0px #00748C; }
  .inputTextLabel.innerInput .form-group.row label {
    position: absolute;
    top: 2px;
    left: 2px;
    background-color: #00748C;
    color: #FFFFFF;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: center;
    font-weight: inherit;
    padding-top: 6px;
    padding-bottom: 6px; }
  .inputTextLabel.innerInput.invest .form-control {
    border-color: #00748C;
    outline: 0;
    -webkit-box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075), 0 0 0px #00748C;
    box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075), 0 0 0px #00748C; }
  .inputTextLabel.innerInput.invest .form-group.row label {
    position: absolute;
    top: 2px;
    left: 2px;
    background-color: #00748C;
    color: #FFFFFF;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: center;
    font-weight: inherit;
    padding-top: 6px;
    padding-bottom: 6px; }
  .inputTextLabel.innerInput.pensionPlan .form-control {
    border-color: #3F96A8;
    outline: 0;
    -webkit-box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075), 0 0 0px #3F96A8;
    box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075), 0 0 0px #3F96A8; }
  .inputTextLabel.innerInput.pensionPlan .form-group.row label {
    position: absolute;
    top: 2px;
    left: 2px;
    background-color: #3F96A8;
    color: #FFFFFF;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: center;
    font-weight: inherit;
    padding-top: 6px;
    padding-bottom: 6px; }
  .inputTextLabel.innerInput.saving .form-control {
    border-color: #7FB9C5;
    outline: 0;
    -webkit-box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075), 0 0 0px #7FB9C5;
    box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075), 0 0 0px #7FB9C5; }
  .inputTextLabel.innerInput.saving .form-group.row label {
    position: absolute;
    top: 2px;
    left: 2px;
    background-color: #7FB9C5;
    color: #FFFFFF;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: center;
    font-weight: inherit;
    padding-top: 6px;
    padding-bottom: 6px; }

/*Small and down*/
@media (max-width: 767px) {
  .inputTextLabel.innerInput .form-group.row label {
    padding-top: 7px;
    padding-bottom: 8px; } }
