@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px); }
  to {
    transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    -moz-transform: translate(0, 100px);
    -ms-transform: translate(0, 100px);
    -o-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -ms-transition: -ms-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    transform: rotate(45deg); }
  .modal-video-close-btn:after {
    transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.fintupSlider h1 {
  font-size: 15px; }

.fintupSlider .titleValue {
  margin-left: 1rem; }

.fintupSlider .rangeslider .rangeslider__handle-tooltip {
  width: 100px; }

.fintupSlider.withLabels {
  margin-bottom: 45px; }

.fintupSlider.withoutImageLabels .rangeslider__labels .rangeslider__label-item {
  top: -40px; }

.fintupSlider .rangeslider__handle {
  z-index: 15;
  background-color: #52d2bc; }

.fintupSlider .rangeslider__labels {
  margin-top: 10px; }

.fintupSlider .rangeslider__labels .rangeslider__label-item {
  top: -50px;
  font-size: 15px; }

.fintupSlider .rangeslider-horizontal {
  height: 2px;
  background-color: #b5b5b5;
  /*box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.4);*/ }
  .fintupSlider .rangeslider-horizontal .rangeslider__fill {
    background-color: #52d2bc;
    /*box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.4);
      -webkit-box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.4);*/ }
  .fintupSlider .rangeslider-horizontal .rangeslider__handle {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    top: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
    box-shadow: 0 0px 0px transparent, 0 0px 0px transparent;
    transition: box-shadow ease .3s; }
    .fintupSlider .rangeslider-horizontal .rangeslider__handle:after {
      width: 0px; }
    .fintupSlider .rangeslider-horizontal .rangeslider__handle:focus {
      outline: -webkit-focus-ring-color auto 0px;
      outline-color: -webkit-focus-ring-color;
      outline-style: auto;
      outline-width: 0px; }

.fintupSlider .rangeslider,
.fintupSlider .rangeslider .rangeslider__fill {
  display: block;
  -webkit-box-shadow: inset 0 1px 3px transparent;
  box-shadow: inset 0 1px 3px transparent; }

.fintupSlider.changing .rangeslider-horizontal .rangeslider__handle {
  border-radius: 30px;
  top: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  box-shadow: 0 0px 4px #52d2bc, 0 0px 4px #52d2bc; }

/* Extra small devices (767px and down) */
@media (max-width: 767px) {
  .fintupSlider .rangeslider__labels .rangeslider__label-item {
    font-size: 12px; } }
