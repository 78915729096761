@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px); }
  to {
    transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    -moz-transform: translate(0, 100px);
    -ms-transform: translate(0, 100px);
    -o-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -ms-transition: -ms-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    transform: rotate(45deg); }
  .modal-video-close-btn:after {
    transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px); }
  to {
    transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    -moz-transform: translate(0, 100px);
    -ms-transform: translate(0, 100px);
    -o-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -ms-transition: -ms-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    transform: rotate(45deg); }
  .modal-video-close-btn:after {
    transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
/**
* New styles of components
*/
*.theme-new {
  font-family: 'Lato' !important;
  font-size: 18px !important; }
  *.theme-new label, *.theme-new .form-control {
    font-family: 'Lato' !important;
    font-size: 18px !important; }
  *.theme-new .react-date-picker__wrapper {
    font-family: 'Lato';
    border: none;
    border-bottom: 2px solid #52d2bc;
    box-shadow: none;
    min-width: 180px;
    margin-bottom: 15px; }
    *.theme-new .react-date-picker__wrapper .react-date-picker__inputGroup {
      display: flex;
      justify-content: center;
      align-items: center; }
    *.theme-new .react-date-picker__wrapper input::placeholder, *.theme-new .react-date-picker__wrapper input::-webkit-input-placeholder {
      font-weight: 400;
      color: #52d2bc5f; }
    *.theme-new .react-date-picker__wrapper .react-date-picker__inputGroup__divider {
      font-size: 30px;
      font-weight: 500;
      color: #52d2bc;
      padding: 0px 10px; }

.document {
  margin-bottom: 15px; }
  .document .row {
    margin-left: 0px; }
  .document .documentText {
    padding-top: 0px;
    color: #979797;
    cursor: pointer;
    padding-left: 0px;
    font-weight: 700; }
  .document .fileDescription {
    font-size: 14px;
    font-weight: 500; }
  .document .documentButtonsText {
    margin-top: 5px; }
  .document.newStyle .documentFooter {
    text-align: center;
    font-size: 17px; }
    .document.newStyle .documentFooter .fintupIcons {
      margin-right: 10px; }
  .document.newStyle .documentContainer {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px; }
    .document.newStyle .documentContainer .documentInner {
      color: #7FB9C5;
      border-color: #7FB9C5;
      border-style: dotted;
      border-width: 1px;
      width: 100%;
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .document.newStyle .documentContainer .documentInner .sk-spinner {
        color: #7FB9C5;
        margin: auto; }
      .document.newStyle .documentContainer .documentInner .fintupIcons {
        margin-left: 2px;
        margin-right: 2px; }
  .document.newStyle.theme-new .documentInner {
    color: #52d2bc !important;
    border-color: #52d2bc !important;
    border-radius: 10px; }
    .document.newStyle.theme-new .documentInner .content {
      /*.icon-wrapper .fa-check{
          //color: yellow;
        }*/ }
      .document.newStyle.theme-new .documentInner .content .iconsFileUpload {
        display: flex;
        justify-content: center;
        align-items: center; }
      .document.newStyle.theme-new .documentInner .content p {
        font-size: 17px;
        font-weight: 500;
        margin-bottom: 5px; }
      .document.newStyle.theme-new .documentInner .content p.or {
        color: #52d2bc5f;
        margin-bottom: 5px; }
      .document.newStyle.theme-new .documentInner .content .icon-wrapper .fa-times {
        color: #EF6776; }
      .document.newStyle.theme-new .documentInner .content span.result {
        margin-left: 10px;
        color: #000000;
        font-weight: 300; }
      .document.newStyle.theme-new .documentInner .content .fintupButton button {
        height: 30px;
        border-radius: 8px;
        padding: 0px; }
        .document.newStyle.theme-new .documentInner .content .fintupButton button div {
          display: flex;
          justify-content: center;
          align-items: center; }
          .document.newStyle.theme-new .documentInner .content .fintupButton button div .fintupIcons {
            margin-right: 5px; }

.document .documentUpdateContainer input[type="file"] {
  display: none; }

.document a.documentLink {
  color: #8a8a8a; }

.document a.documentLink:hover {
  text-decoration: none; }

.document a.documentLink:focus {
  text-decoration: none;
  outline-color: transparent; }

/* Small devices (tablets, 768px and up) */
@media (max-width: 768px) {
  .document .fileDescription {
    margin-top: 10px; } }
