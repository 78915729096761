@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px); }
  to {
    transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    -moz-transform: translate(0, 100px);
    -ms-transform: translate(0, 100px);
    -o-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -ms-transition: -ms-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    transform: rotate(45deg); }
  .modal-video-close-btn:after {
    transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.registerNew {
  margin-top: 20px; }
  .registerNew .headerStepsBlock img {
    width: 210px;
    height: 125px; }
  .registerNew .headerStepsBlock h4 {
    color: #00748C;
    font-size: 20px;
    font-weight: bold; }
  .registerNew .headerStepsBlock p {
    font-size: 14px;
    line-height: 1.67;
    letter-spacing: 0.32px; }
  .registerNew .headerStepsBlock .previous {
    cursor: pointer; }
  .registerNew .headerStepsBlock .breadcrumbBlock {
    margin-bottom: 20px; }
    .registerNew .headerStepsBlock .breadcrumbBlock .crumb {
      width: 150px;
      /*
        * Mobile
        */
      height: 40px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 33px; }
      @media (max-width: 767px) {
        .registerNew .headerStepsBlock .breadcrumbBlock .crumb {
          width: 90px; } }
      .registerNew .headerStepsBlock .breadcrumbBlock .crumb .icon-wrapper .fa {
        font-size: 18px; }
      .registerNew .headerStepsBlock .breadcrumbBlock .crumb .icon-wrapper, .registerNew .headerStepsBlock .breadcrumbBlock .crumb .fintupIcons {
        margin-left: 10px;
        font-weight: 300;
        /*
          * Mobile
          */ }
        @media (max-width: 767px) {
          .registerNew .headerStepsBlock .breadcrumbBlock .crumb .icon-wrapper, .registerNew .headerStepsBlock .breadcrumbBlock .crumb .fintupIcons {
            margin-left: 2px; } }
    .registerNew .headerStepsBlock .breadcrumbBlock .bread:not(.next):after {
      position: absolute;
      content: "";
      height: 2px;
      width: calc(100% - 150px);
      margin-left: calc(150px / 2);
      top: 50%;
      z-index: 100000;
      /*
          * Mobile
          */ }
      @media (max-width: 767px) {
        .registerNew .headerStepsBlock .breadcrumbBlock .bread:not(.next):after {
          width: calc(100% - 90px);
          margin-left: calc(90px / 2); } }
    .registerNew .headerStepsBlock .breadcrumbBlock .bread.previous:after {
      background: #7FB9C5; }
    .registerNew .headerStepsBlock .breadcrumbBlock .bread.previous .crumb {
      background-color: #FFFFFF;
      border: 1px solid #7FB9C5;
      color: #7FB9C5; }
    .registerNew .headerStepsBlock .breadcrumbBlock .bread.active:after {
      background: #e6e6e6; }
    .registerNew .headerStepsBlock .breadcrumbBlock .bread.active .crumb {
      background-color: #FF5200;
      border: 1px solid #FF5200;
      color: #FFFFFF;
      font-weight: bold; }
    .registerNew .headerStepsBlock .breadcrumbBlock .bread.next .crumb {
      background-color: #FFFFFF;
      margin: auto;
      border: 1px solid #e6e6e6;
      color: #e6e6e6; }
  .registerNew .registerBlock {
    margin-top: 20px; }
    .registerNew .registerBlock h3 {
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 0px; }
    .registerNew .registerBlock .formBlock .buttons {
      display: flex;
      justify-content: center;
      align-items: center; }
      .registerNew .registerBlock .formBlock .buttons button, .registerNew .registerBlock .formBlock .buttons .socialLogin {
        margin: 20px 40px 0px;
        /*
          * Mobile
          */ }
        @media (max-width: 767px) {
          .registerNew .registerBlock .formBlock .buttons button, .registerNew .registerBlock .formBlock .buttons .socialLogin {
            margin: 10px 20px 0px; } }
      .registerNew .registerBlock .formBlock .buttons .socialLogin .react-google-oauth-button-login {
        width: 150px !important;
        height: 45px;
        border-radius: 30px;
        border: 2px solid #52d2bc;
        font-weight: bold;
        text-transform: uppercase; }
        .registerNew .registerBlock .formBlock .buttons .socialLogin .react-google-oauth-button-login:hover {
          opacity: 1; }
        .registerNew .registerBlock .formBlock .buttons .socialLogin .react-google-oauth-button-login:active {
          color: inherit;
          border: 2px solid #52d2bc;
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
        .registerNew .registerBlock .formBlock .buttons .socialLogin .react-google-oauth-button-login .react-google-oauth-button-border {
          display: flex;
          justify-content: center;
          align-items: center; }
    .registerNew .registerBlock .formBlock .fieldsBlock {
      margin-top: 25px; }
      .registerNew .registerBlock .formBlock .fieldsBlock .buttonsFields {
        display: flex;
        justify-content: space-around;
        /*
          * Mobile
          */ }
        @media (max-width: 767px) {
          .registerNew .registerBlock .formBlock .fieldsBlock .buttonsFields {
            margin-bottom: 20px; } }
    .registerNew .registerBlock .formBlock .terms {
      width: 50%;
      text-align: left;
      margin-bottom: 20px;
      /*
        * Mobile
        */ }
      .registerNew .registerBlock .formBlock .terms .checkboxTerm {
        margin-top: 10px;
        margin-bottom: 0px; }
        .registerNew .registerBlock .formBlock .terms .checkboxTerm label {
          font-size: 14px !important; }
      @media (max-width: 767px) {
        .registerNew .registerBlock .formBlock .terms {
          width: 90%; }
          .registerNew .registerBlock .formBlock .terms .checkboxTerm {
            margin-top: 20px; } }
