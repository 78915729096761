@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap);
@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
@media (min-width: 768px) {
  .navbar-header {
    float: none; }
  a.navbar-brand {
    width: 100%; }
  a.navbar-brand .default-logo {
    margin: auto; }
  .fintupHeader {
    margin-bottom: 20px; } }

.default-logo {
  height: 45px;
  cursor: pointer; }

.nav.navbar {
  margin-bottom: 5px; }

.nav .navbar-brand {
  padding-top: 10px; }

.navbar-toggle[aria-expanded="false"] .closeMenuIcon {
  display: none; }

.navbar-toggle[aria-expanded="true"] .icon-bar {
  display: none; }

.navbar-right {
  margin-right: 25px;
  margin-top: -30px; }

.navbar-default {
  background-color: #ffffff;
  border-color: #ffffff; }

.navbar-nav > li {
  margin-left: 15px; }

.navbar .divider-vertical {
  height: 50px;
  margin: 0 9px;
  border-right: 1px solid #ffffff;
  border-left: 1px solid #f2f2f2; }

.nav .navbar-toggle {
  width: 45px;
  float: left;
  margin-left: 15px;
  margin-right: 0px;
  border-radius: 20px; }

.fintup .navbar-default .navbar-nav > li > a:focus {
  outline: none; }

.nav .notificationBadge {
  position: absolute;
  z-index: 100;
  font-size: 10px;
  background-color: #FF0000;
  padding-bottom: 2px;
  padding-top: 2px;
  padding-right: 4px;
  padding-left: 4px;
  left: 30px;
  top: 0px; }

@media (max-width: 767px) {
  .navbar-collapse .nav > .divider-vertical {
    display: none; }
  .fintupHeader .navbar-default {
    background-color: #00748C;
    padding-top: 10px;
    padding-bottom: 10px; }
  .nav .navbar-brand {
    padding-left: 0px;
    margin-left: 0px;
    float: none; }
  .nav.navbar {
    margin-bottom: 0px; }
  .nav .brandContainer {
    text-align: center; }
  .nav .navbar-brand > img {
    display: inherit; }
  .nav .navbar-collapse {
    background-color: #191919; } }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.icon-background4 {
  color: #FFFFFF; }

.icon-background6 {
  color: #f2f2f2; }

.userInfoIcon {
  color: #a2a2a2; }

.userInfoText p {
  margin-bottom: 0px;
  color: #666666; }

.section-separator:after {
  content: ' ';
  display: block;
  float: left;
  background: #ADADAD;
  margin: 0 10px;
  height: 34px;
  width: 1px; }

.navPrivate {
  height: 72px;
  width: 100%;
  position: fixed;
  z-index: 2;
  background-color: #00748C;
  padding-left: 20px;
  padding-right: 20px;
  color: #FFFFFF;
  font-size: 18px; }
  .navPrivate .globalPositionLink {
    cursor: pointer; }
  .navPrivate .hello {
    margin-top: 25px; }
  .navPrivate .logout {
    margin-top: 23px;
    margin-right: 15px;
    margin-left: 0px;
    cursor: pointer; }
  .navPrivate .sep {
    margin-top: 12px; }
    .navPrivate .sep img {
      height: 40px;
      margin-top: 5px; }
  .navPrivate .notificationContainer {
    margin-top: 5px;
    margin-right: 20px; }
  .navPrivate .default-logo {
    margin-top: 10px; }
  .navPrivate .centralContainer {
    display: flex;
    align-items: center;
    justify-content: center; }
    .navPrivate .centralContainer .centralContent {
      margin-top: 25px;
      margin-left: 15px;
      margin-right: 15px;
      cursor: pointer; }
      .navPrivate .centralContainer .centralContent.active {
        font-weight: bold; }
  .navPrivate .inviteContainer {
    margin-top: 25px;
    margin-right: 15px;
    cursor: pointer; }
    .navPrivate .inviteContainer.active {
      font-weight: bold; }
  .navPrivate .profileContainer {
    cursor: pointer; }
    .navPrivate .profileContainer img {
      margin-top: 25px;
      margin-right: 25px;
      margin-left: 15px; }

@media (max-width: 767px) {
  .navPrivate {
    height: 115px; }
    .navPrivate .logout {
      margin-right: 0px; }
    .navPrivate .centralContainer .centralContent {
      margin-top: 15px; } }

@charset "UTF-8";
@font-face {
  font-family: fintupIcons;
  src: url("https://s3-eu-west-1.amazonaws.com/cdn.fintup.com/fonts/fintupIcons20191202.woff"); }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.CircleIcon {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 1px solid #00748C;
  display: flex;
  background-color: #00748C;
  color: white;
  align-items: center;
  justify-content: center; }
  .CircleIcon--small {
    width: 20px;
    height: 20px;
    font-size: 17px; }
  .CircleIcon__icon {
    display: flex;
    flex: 1 1;
    border-radius: 100%;
    justify-content: center;
    align-items: center; }

.fintupIcons {
  font-family: fintupIcons; }
  .fintupIcons.okColor {
    color: #00748C; }
  .fintupIcons.pensionPlanColor {
    color: #3F96A8; }
  .fintupIcons.savingColor {
    color: #7FB9C5; }
  .fintupIcons.cancelColor {
    color: #FB4D6D; }
  .fintupIcons.newColor {
    color: #52d2bc5f; }

.fintupIcons.icon-size-75 {
  font-size: 75px; }

.fintupIcons.icon-size-50 {
  font-size: 50px; }

.fintupIcons.icon-size-40 {
  font-size: 40px; }

.fintupIcons.icon-size-35 {
  font-size: 35px; }

.fintupIcons.icon-size-30 {
  font-size: 30px; }

.fintupIcons.icon-size-25 {
  font-size: 25px; }

.fintupIcons.icon-size-20 {
  font-size: 20px; }

.fintupIcons.icon-size-15 {
  font-size: 15px; }

.fintupIcons.icon-size-10 {
  font-size: 10px; }

.fintupIcons.icon-size-8 {
  font-size: 8px; }

.fintupIcons.icon-weight-500 {
  font-weight: 500; }

.fintupIcons.altavoz:before {
  content: "\E93A"; }

.fintupIcons.bell:before {
  content: "\E901"; }

.fintupIcons.exit:before {
  content: "\E902"; }

.fintupIcons.analyze:before {
  content: "\E903"; }

.fintupIcons.academia:before {
  content: "\E904"; }

.fintupIcons.check-done:before {
  content: "\E93B"; }

.fintupIcons.refresh:before {
  content: "\E93C"; }

.fintupIcons.test:before {
  content: "\E93D"; }

.fintupIcons.simulador:before {
  content: "\E93E"; }

.fintupIcons.contratar:before {
  content: "\E905"; }

.fintupIcons.herramientas:before {
  content: "\E906"; }

.fintupIcons.midinero:before {
  content: "\E907"; }

.fintupIcons.miperfil:before {
  content: "\E908"; }

.fintupIcons.retirar:before {
  content: "\E90B"; }

.fintupIcons.pensiones:before {
  content: "\E90C"; }

.fintupIcons.inversion:before {
  content: "\E91D"; }

.fintupIcons.info:before {
  content: "\E91E"; }

.fintupIcons.graphic:before {
  content: "\E91F"; }

.fintupIcons.graphicCircular:before {
  content: "\E920"; }

.fintupIcons.aportar:before {
  content: "\E921"; }

.fintupIcons.plus:before {
  content: "\E900"; }

.fintupIcons.ahorro:before {
  content: "\E934"; }

.fintupIcons.cancel:before {
  content: "\E909"; }

.fintupIcons.check:before {
  content: "\E90A"; }

.fintupIcons.arrow-right:before {
  content: "\E932"; }

.fintupIcons.arrow-left:before {
  content: "\E933"; }

.fintupIcons.camera:before {
  content: "\E935"; }

.fintupIcons.flecha-seleccionable-izquierda:before {
  content: "\E936"; }

.fintupIcons.flecha-seleccionable-derecha:before {
  content: "\E937"; }

.fintupIcons.flecha-seleccionable-arriba:before {
  content: "\E938"; }

.fintupIcons.flecha-seleccionable-abajo:before {
  content: "\E939"; }

.fintupIcons.euroDown:before {
  content: "\E93F"; }

.fintupIcons.mundo:before {
  content: "\E940"; }

.fintupIcons.infinito:before {
  content: "\E941"; }

.fintupIcons.brujula:before {
  content: "\E942"; }

.fintupIcons.libro:before {
  content: "\E944"; }

.fintupIcons.promediar:before {
  content: "\E943"; }

.fintupIcons.actualidad:before {
  content: "\E945"; }

.fintupIcons.articulo:before {
  content: "\E946"; }

.fintupIcons.corazon:before {
  content: "\E947"; }

.fintupIcons.filosofia:before {
  content: "\E948"; }

.fintupIcons.play:before {
  content: "\E949"; }

.fintupIcons.cloud:before {
  content: "\E94A"; }

.fintupIcons.phone:before {
  content: "\E94B"; }

.fintupIcons.phone2:before {
  content: "\E958"; }

.fintupIcons.mail:before {
  content: "\E94C"; }

.fintupIcons.invite:before {
  content: "\E94D"; }

.fintupIcons.inviteplus:before {
  content: "\E94E"; }

.fintupIcons.copy:before {
  content: "\E950"; }

.fintupIcons.circle:before {
  content: "\E951"; }

.fintupIcons.circle-plus:before {
  content: "\E94F"; }

.fintupIcons.circle-minus:before {
  content: "\E951"; }

.fintupIcons.pdf-icon:before {
  content: "\E954"; }

.fintupIcons.jpg-icon:before {
  content: "\E952"; }

.fintupIcons.png-icon:before {
  content: "\E953"; }

.fintupIcons.dni-anverso:before {
  content: "\E956"; }

.fintupIcons.dni-reverso:before {
  content: "\E955"; }

.fintupIcons.file:before {
  content: "\E957"; }

.fintupIcons.inversion-coloreado .path1:before {
  content: "\E90D";
  color: #00748C; }

.fintupIcons.inversion-coloreado .path2:before {
  content: "\E90E";
  color: #fff;
  margin-left: -1em; }

.fintupIcons.inversion-coloreado .path3:before {
  content: "\E90F";
  color: #7fb9c5;
  margin-left: -1em; }

.fintupIcons.inversion-coloreado .path4:before {
  content: "\E910";
  color: #00748C;
  margin-left: -1em; }

.fintupIcons.inversion-coloreado .path5:before {
  content: "\E911";
  color: #00748C;
  margin-left: -1em; }

.fintupIcons.inversion-coloreado .path6:before {
  content: "\E912";
  color: #00748C;
  margin-left: -1em; }

.fintupIcons.inversion-coloreado .path7:before {
  content: "\E913";
  color: #7fb9c5;
  margin-left: -1em; }

.fintupIcons.inversion-coloreado .path8:before {
  content: "\E914";
  color: #000;
  margin-left: -1em; }

.fintupIcons.inversion-coloreado .path9:before {
  content: "\E915";
  color: #000;
  margin-left: -1em; }

.fintupIcons.inversion-coloreado .path10:before {
  content: "\E916";
  color: #000;
  margin-left: -1em; }

.fintupIcons.inversion-coloreado .path11:before {
  content: "\E917";
  color: #000;
  margin-left: -1em; }

.fintupIcons.inversion-coloreado .path12:before {
  content: "\E918";
  color: #000;
  margin-left: -1em; }

.fintupIcons.inversion-coloreado .path13:before {
  content: "\E919";
  color: #000;
  margin-left: -1em; }

.fintupIcons.inversion-coloreado .path14:before {
  content: "\E91A";
  color: #000;
  margin-left: -1em; }

.fintupIcons.inversion-coloreado .path15:before {
  content: "\E91B";
  color: #000;
  margin-left: -1em; }

.fintupIcons.inversion-coloreado .path16:before {
  content: "\E91C";
  color: #000;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path1:before {
  content: "\E922";
  color: #000; }

.fintupIcons.ahorro-coloreado .path2:before {
  content: "\E923";
  color: #00748C;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path3:before {
  content: "\E924";
  color: #fff;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path4:before {
  content: "\E925";
  color: #7fb9c5;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path5:before {
  content: "\E926";
  color: #7fb9c5;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path6:before {
  content: "\E927";
  color: #000;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path7:before {
  content: "\E928";
  color: #7fb9c5;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path8:before {
  content: "\E929";
  color: #7fb9c5;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path9:before {
  content: "\E92A";
  color: #3f96a8;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path10:before {
  content: "\E92B";
  color: #7fb9c5;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path11:before {
  content: "\E92C";
  color: #7fb9c5;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path12:before {
  content: "\E92D";
  color: #7fb9c5;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path13:before {
  content: "\E92E";
  color: #7fb9c5;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path14:before {
  content: "\E92F";
  color: #7fb9c5;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path15:before {
  content: "\E930";
  color: #7fb9c5;
  margin-left: -1em; }

.fintupIcons.ahorro-coloreado .path16:before {
  content: "\E931";
  color: #00748C;
  margin-left: -1em; }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.navPublicNew {
  height: 80px;
  width: 100%;
  z-index: 2;
  background-color: #00748C;
  padding-left: 20px;
  padding-right: 20px;
  color: #FFFFFF;
  font-size: 18px;
  display: flex;
  align-items: center; }
  .navPublicNew .default-logo-new {
    cursor: pointer;
    max-width: 200px;
    padding-top: 5px; }
  .navPublicNew .navPublicDesktop {
    width: 100%;
    display: flex;
    justify-content: space-between; }
  .navPublicNew .ctaButton .btn-fintup {
    background-color: #FF5200; }
  .navPublicNew .ctaButton2 .btn-fintup {
    background-color: #52d2bc; }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.fintupButton .btn-m {
  width: 100px; }

.fintupButton .btn-full {
  width: 100%; }

.fintupButton .btn.btnSelector.login-color.saving {
  background-color: #FFFFFF;
  color: #7FB9C5; }

.fintupButton .btn.btnSelector.saving {
  border-color: #7FB9C5; }

.fintupButton .btn-fintup {
  color: #FFFFFF;
  background-color: #52d2bc;
  border-radius: 25px;
  font-size: 15px;
  font-weight: 700;
  transition: ease all .3s;
  height: 50px; }
  .fintupButton .btn-fintup:hover {
    background-color: black; }
  .fintupButton .btn-fintup.btn-small {
    height: 34px; }
  .fintupButton .btn-fintup.btn-only-icon {
    border-radius: 50px;
    height: 80px;
    padding: 12px;
    width: 80px;
    font-size: 30px; }
  .fintupButton .btn-fintup.principal {
    background-color: #bfdce2;
    color: #00748C;
    transition: ease all .3s; }
    .fintupButton .btn-fintup.principal:hover {
      background-color: #00748C;
      color: #bfdce2; }

.fintupButton .btn-bold {
  font-size: 15px;
  font-weight: 800;
  padding-top: 10px;
  padding-bottom: 10px; }

.fintup .btn-l {
  width: 150px; }

.fintup .btn-2l {
  width: 310px; }

.fintup .btn-xl {
  width: 200px; }

.fintup .fintupButton .btn-small {
  font-size: 11px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 25px; }

.fintup .fintupButton .btnSelector.btn-small {
  font-size: inherit; }

.fintup .fintupButton .alt {
  border-width: 1px;
  border-style: solid;
  border-color: #00748C;
  background-color: #FFFFFF;
  color: #00748C; }
  .fintup .fintupButton .alt:hover {
    background-color: #9b9b9b;
    color: white; }

.fintup .fintupButton .btn-2s {
  font-size: 11px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 6px;
  padding-right: 6px;
  width: 190px; }

.fintup .fintupButton .btn-2halfs {
  width: 90px; }

.fintup .fintupButton .btn-xxl {
  width: 260px; }

.fintup .fintupButton .btn-xxxl {
  width: 350px; }

.fintupButton .sk-spinner {
  margin: auto;
  color: #fff; }

.fintupButton.btn-loading .login-color {
  background-color: #758f96 !important;
  border-color: #758f96 !important; }

.fintupButton .btn:focus {
  outline: 0px auto -webkit-focus-ring-color;
  outline-offset: -0px; }

.fintupButton.btnBold .btn-fintup {
  font-weight: 700; }

.fintupButton.noBorder .btn-fintup {
  border-width: 0px; }

.fintupButton .btn.btnSelector {
  border-radius: 10px;
  border-color: #00748C;
  border-style: solid;
  border-width: 1px;
  background-color: #00748C;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 18px; }

.fintupButton .btn.btnSelector.pensionPlan {
  border-color: #3F96A8;
  background-color: #3F96A8; }

.fintupButto .btn.btnSelector.login-color.pensionPlan {
  background-color: transparent;
  color: #3F96A8; }

.fintupButton .btn.btnSelector.login-color {
  background-color: transparent;
  color: #00748C; }

.fintupButton .btn.btnStepCard {
  width: 200px;
  border-radius: 25px;
  border-color: #000000;
  border-style: solid;
  border-width: 1px;
  background-color: #ffffff;
  color: #000000;
  font-size: 20px; }

.fintupButton .btn-fintup.btn.disabled,
.fintupButton .btn-fintup.btn[disabled],
.fintupButton .btn-fintupfieldset[disabled] .btn {
  opacity: 1;
  background-color: #bdbdbd;
  border-color: #bdbdbd; }

.fintup .fintupButton.btn-trash {
  padding: 0px; }
  .fintup .fintupButton.btn-trash button {
    padding: 0px;
    width: 20px;
    height: 20px;
    background: #EF6776;
    display: flex;
    justify-content: center;
    align-items: center; }
    .fintup .fintupButton.btn-trash button img {
      width: 12px;
      height: 12px; }

.fintup .fintupButton.buttonPrev button {
  border-radius: 50px;
  height: 50px;
  padding: 12px;
  width: 50px;
  font-size: 15px;
  background-color: #FFFFFF;
  border: solid 1.5px #e6e6e6;
  color: #e6e6e6;
  margin-right: 10px; }

.fintup .fintupButton.buttonAttention button {
  background-color: #FF5200; }

.fintup .fintupButton.buttonEmail button {
  color: #000000;
  border-radius: 30px;
  background-color: transparent;
  height: 45px;
  width: 150px;
  font-size: 15px;
  border-radius: 30px;
  border: 2px solid #52d2bc;
  font-weight: bold; }
  .fintup .fintupButton.buttonEmail button div {
    display: flex;
    justify-content: center;
    align-items: center; }
    .fintup .fintupButton.buttonEmail button div img {
      height: 18px;
      margin-right: 15px; }
    .fintup .fintupButton.buttonEmail button div p {
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 0px; }

.fintupButton.buttonPrevMiniTest button {
  border-radius: 50px !important;
  height: 50px !important;
  padding: 12px !important;
  width: 50px !important;
  font-size: 15px !important;
  background-color: #FFFFFF !important;
  border: solid 1.5px #979797 !important;
  color: #979797 !important;
  margin-right: 10px !important; }
  .fintupButton.buttonPrevMiniTest button span::before {
    margin-right: 3px; }

.fintupButton.buttonWhiteAndGreen button {
  border-radius: 50px !important;
  height: 35px !important;
  padding: 5px 10px !important;
  font-size: 13px !important;
  background-color: #FFFFFF !important;
  border: 1px solid #52d2bc !important;
  color: #000000 !important; }
  .fintupButton.buttonWhiteAndGreen button span::before {
    margin-right: 3px; }

/* Extra small devices (767px and down) */
@media (max-width: 767px) {
  .fintup .fintupButton .btn-small {
    font-size: 12px; }
  .fintupButton .btn.btnSelector {
    font-size: 17px; } }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.footer-bottom {
  position: fixed;
  bottom: 0px;
  width: 100%;
  color: #FFFFFF;
  line-height: 50px;
  text-align: center;
  background-color: #00748C; }

@media (max-width: 767px) {
  .footer-bottom {
    line-height: 30px;
    background-color: transparent;
    color: #00748C;
    position: inherit; } }

.hideAlert{
  display: none;
}
@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.singleFormContainer.mainContainer.singleBody {
  min-height: calc(100vh - 50px); }

.singleFormContainer.mainContainer {
  min-height: calc(100vh - 117px);
  background-color: #00748C;
  border-color: #00748C;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 90px;
  padding-right: 75px;
  min-height: -o-calc(100vh - 100px);
  /* opera */
  /* google, safari */
  min-height: -moz-calc(100vh - 100px);
  /* firefox */ }

.fintup.fintupPrivateContainer.hasOperationBar .singleFormContainer.mainContainer {
  min-height: calc(100vh - 162px); }

.singleFormContainer.mainContainer.double {
  padding-left: 0px;
  padding-right: 0px; }

.singleFormContainer.double .form-container {
  margin-left: 25px;
  margin-right: 25px;
  padding-top: 10px;
  padding-left: 25px;
  padding-bottom: 5px;
  padding-right: 25px; }

.singleFormContainer .form-container {
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  outline: 0;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  margin-left: 150px;
  margin-right: 150px;
  margin-top: 25px;
  padding: 35px; }

@media (max-width: 767px) {
  .singleFormContainer .form-container {
    margin-left: 0px;
    margin-right: 0px; }
  .singleFormContainer.mainContainer.double {
    padding-left: 0px;
    padding-right: 0px;
    min-height: inherit;
    padding-top: 0px;
    padding-bottom: 0px; }
  .singleFormContainer.mainContainer {
    padding-left: 50px;
    padding-right: 40px;
    background-color: transparent;
    border-color: transparent; } }

.singleFormContainer h2 {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 25px; }

.singleFormContainer .formLegend {
  margin-bottom: 15px;
  text-align: center;
  color: #00748C; }

.singleFormContainer .alert {
  margin-bottom: 5px; }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.cnp-app .home .registerContainer {
  display: none; }

.cnp-app .home .registerTextContainer {
  display: block;
  float: left;
  text-align: left;
  color: #00748C;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 14px;
  cursor: pointer; }

@media (min-width: 992px) {
  .cnp-app .home .loginContainer {
    left: 25%; } }

@media (min-width: 768px) {
  .cnp-app .home .loginContainer {
    width: 58.33333333%; }
    .cnp-app .home .loginContainer .loginButtonContainer {
      width: 100%;
      padding-left: 0px;
      padding-right: 0px; } }

@media (max-width: 767px) {
  .cnp-app .home .forgetContainer {
    text-align: center;
    margin-top: 15px;
    float: none; }
  .cnp-app .home .registerTextContainer {
    text-align: center;
    margin-top: 15px;
    float: none; } }

.home .registerDot {
  margin-left: 75px; }
  .home .registerDot img {
    margin-right: 15px; }

.home .separator {
  float: left;
  width: 40%; }
  .home .separator hr {
    margin-top: 10px; }

.home .separatorCenter {
  float: left;
  width: 20%;
  text-align: center;
  color: #00748C; }

.home .forgetContainer {
  float: right;
  text-align: right;
  color: #00748C;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 14px;
  cursor: pointer; }

.home .registerTextContainer {
  display: none; }

@media (min-width: 767px) {
  .cnp-app .home .form-container {
    min-height: 380px; } }

@media (max-width: 767px) {
  .home {
    padding-bottom: 50px; }
    .home .registerDot {
      margin-left: 0px; }
    .home .separator {
      float: left;
      width: 0%; }
    .home .separatorCenter {
      float: left;
      width: 100%;
      text-align: center;
      color: #00748C; } }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.socialLogin .react-google-oauth-button-login {
  height: 36px;
  background-color: inherit;
  color: #2d2d2d;
  border-style: solid;
  border-color: #2d2d2d;
  border-width: 1px;
  border-radius: 10px; }

.socialLogin .react-google-oauth-button-iconWrapper {
  background-color: transparent; }

.socialLogin .react-google-oauth-button-iconWrapper {
  padding-top: 8px;
  padding-bottom: 8px; }

.socialLogin .react-google-oauth-button-span {
  font-family: inherit;
  font-size: 15px;
  font-weight: 700;
  margin-left: 0px;
  margin-right: 15px; }

.socialLogin .react-google-oauth-button-span {
  line-height: 36px; }

.socialLogin {
  text-align: center; }

.socialLogin.theme-new {
  font-size: auto !important; }
  .socialLogin.theme-new .react-google-oauth-button-login {
    width: 100% !important;
    height: 45px;
    border-radius: 30px;
    background-color: #4285f4;
    padding: 3px 0px 3px 5px;
    border: 2px solid #4285f4; }
    .socialLogin.theme-new .react-google-oauth-button-login:hover {
      opacity: unset; }
    .socialLogin.theme-new .react-google-oauth-button-login:active {
      color: #4285f4;
      -webkit-filter: brightness(90%);
              filter: brightness(90%); }
    .socialLogin.theme-new .react-google-oauth-button-login .react-google-oauth-button-iconWrapper {
      font-size: 18px;
      height: 100%;
      display: flex;
      align-items: center;
      background: #FFFFFF;
      border-radius: 100%;
      padding: 8px; }
    .socialLogin.theme-new .react-google-oauth-button-login .react-google-oauth-button-span {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FFFFFF; }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
/**
* New styles of components
*/
*.theme-new {
  font-family: 'Lato' !important;
  font-size: 18px !important; }
  *.theme-new label, *.theme-new .form-control {
    font-family: 'Lato' !important;
    font-size: 18px !important; }
  *.theme-new .react-date-picker__wrapper {
    font-family: 'Lato';
    border: none;
    border-bottom: 2px solid #52d2bc;
    box-shadow: none;
    min-width: 180px;
    margin-bottom: 15px; }
    *.theme-new .react-date-picker__wrapper .react-date-picker__inputGroup {
      display: flex;
      justify-content: center;
      align-items: center; }
    *.theme-new .react-date-picker__wrapper input::-webkit-input-placeholder {
      font-weight: 400;
      color: #52d2bc5f; }
    *.theme-new .react-date-picker__wrapper input::placeholder, *.theme-new .react-date-picker__wrapper input::-webkit-input-placeholder {
      font-weight: 400;
      color: #52d2bc5f; }
    *.theme-new .react-date-picker__wrapper .react-date-picker__inputGroup__divider {
      font-size: 30px;
      font-weight: 500;
      color: #52d2bc;
      padding: 0px 10px; }

.inputTextLabel {
  /* enable absolute positioning */
  /* align icon */ }
  .inputTextLabel.underLineControl .form-group.row {
    margin-right: 0px;
    margin-left: 0px; }
    .inputTextLabel.underLineControl .form-group.row label {
      font-weight: normal;
      color: #00748C;
      margin-top: 10px; }
  .inputTextLabel.underLineControl .form-control {
    border-top-width: 0px;
    border-top-style: solid;
    border-left-width: 0px;
    border-left-style: none;
    border-right-width: 0px;
    border-right-style: none;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #00748C;
    box-shadow: none;
    transition: none; }
    .inputTextLabel.underLineControl .form-control[readonly] {
      background-color: transparent; }
    .inputTextLabel.underLineControl .form-control:focus {
      border-color: none;
      outline: 0;
      box-shadow: none; }
  .inputTextLabel.theme-new {
    letter-spacing: normal; }
    .inputTextLabel.theme-new .form-group.row {
      margin-right: 0px;
      margin-left: 0px; }
      .inputTextLabel.theme-new .form-group.row label {
        font-weight: normal;
        color: #00748C;
        margin-top: 10px; }
    .inputTextLabel.theme-new .inputLegendLink {
      font-size: 13px; }
    .inputTextLabel.theme-new .form-control {
      padding-left: 0px;
      padding-bottom: 0px;
      border: 0px;
      box-shadow: none;
      transition: none;
      border-bottom: 2px solid #52d2bc;
      height: auto;
      color: #000000; }
      .inputTextLabel.theme-new .form-control::-webkit-input-placeholder {
        font-weight: 400;
        color: #979797;
        font-size: 16px; }
      .inputTextLabel.theme-new .form-control::placeholder, .inputTextLabel.theme-new .form-control::-webkit-input-placeholder {
        font-weight: 400;
        color: #979797;
        font-size: 16px; }
      .inputTextLabel.theme-new .form-control[readonly] {
        background-color: #efefef; }
      .inputTextLabel.theme-new .form-control:focus {
        border-color: #52d2bc;
        outline: 0;
        box-shadow: none; }
    .inputTextLabel.theme-new.is-error .form-control {
      border-bottom: 2px solid #FB4D6D;
      /*&::placeholder, &::-webkit-input-placeholder {
          color: $color-cross-element-minitest;
        }*/ }
    .inputTextLabel.theme-new.is-error .errorText {
      color: #FB4D6D;
      font-size: 14px;
      display: block;
      margin-top: 5px;
      text-align: left; }
  .inputTextLabel.full-width {
    width: 100%; }
  .inputTextLabel .required-label {
    color: #FB4D6D;
    font-weight: 700;
    font-size: 14px;
    margin-left: 5px; }
  .inputTextLabel label {
    margin-bottom: 10px; }
  .inputTextLabel .form-group.row label {
    margin-bottom: 5px;
    margin-top: 5px; }
  .inputTextLabel .form-group.row label.rightLabel {
    margin-top: 0px; }
  .inputTextLabel .form-group.row label.rightLabel.dividerLabel {
    border-left-color: silver;
    border-left-width: 1px;
    border-left-style: solid;
    padding-left: 10px; }
  .inputTextLabel label.htmlLabel {
    font-weight: inherit; }
  .inputTextLabel .appendText {
    margin-left: -15px; }
  .inputTextLabel .form-control {
    border-radius: 0px; }
  .inputTextLabel input.form-control::-webkit-input-placeholder {
    font-weight: 700; }
  .inputTextLabel input.form-control::placeholder {
    font-weight: 700; }
  .inputTextLabel .inner-addon {
    position: relative;
    /* style icon */ }
    .inputTextLabel .inner-addon .addonContent {
      position: absolute;
      top: 5px;
      /* pointer-events: none; */ }
  .inputTextLabel .left-addon .addonContent {
    left: 0px; }
  .inputTextLabel .left-addon input {
    padding-left: 50px; }
  .inputTextLabel .right-addon .addonContent {
    right: 0px; }
  .inputTextLabel .right-addon input {
    padding-right: 50px; }
  .inputTextLabel .addonContent .fintupIcons {
    margin-left: 5px;
    cursor: pointer; }
  .inputTextLabel .form-control:focus {
    border-color: #00748C;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #00748C; }
  .inputTextLabel.innerInput .form-control {
    border-color: #00748C;
    outline: 0;
    box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075), 0 0 0px #00748C; }
  .inputTextLabel.innerInput .form-group.row label {
    position: absolute;
    top: 2px;
    left: 2px;
    background-color: #00748C;
    color: #FFFFFF;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: center;
    font-weight: inherit;
    padding-top: 6px;
    padding-bottom: 6px; }
  .inputTextLabel.innerInput.invest .form-control {
    border-color: #00748C;
    outline: 0;
    box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075), 0 0 0px #00748C; }
  .inputTextLabel.innerInput.invest .form-group.row label {
    position: absolute;
    top: 2px;
    left: 2px;
    background-color: #00748C;
    color: #FFFFFF;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: center;
    font-weight: inherit;
    padding-top: 6px;
    padding-bottom: 6px; }
  .inputTextLabel.innerInput.pensionPlan .form-control {
    border-color: #3F96A8;
    outline: 0;
    box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075), 0 0 0px #3F96A8; }
  .inputTextLabel.innerInput.pensionPlan .form-group.row label {
    position: absolute;
    top: 2px;
    left: 2px;
    background-color: #3F96A8;
    color: #FFFFFF;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: center;
    font-weight: inherit;
    padding-top: 6px;
    padding-bottom: 6px; }
  .inputTextLabel.innerInput.saving .form-control {
    border-color: #7FB9C5;
    outline: 0;
    box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075), 0 0 0px #7FB9C5; }
  .inputTextLabel.innerInput.saving .form-group.row label {
    position: absolute;
    top: 2px;
    left: 2px;
    background-color: #7FB9C5;
    color: #FFFFFF;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: center;
    font-weight: inherit;
    padding-top: 6px;
    padding-bottom: 6px; }

/*Small and down*/
@media (max-width: 767px) {
  .inputTextLabel.innerInput .form-group.row label {
    padding-top: 7px;
    padding-bottom: 8px; } }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.aviso-sego {
  border: 2px solid #E19805;
  background-color: #FFFAF0;
  padding: 16px;
  font-size: 14px;
  margin: 0 auto;
  margin-bottom: 20px; }
  .aviso-sego .material-symbols-outlined {
    color: #F9A806;
    font-size: 36px;
    width: 36px;
    height: 36px; }
  .aviso-sego a {
    color: black !important; }

.no-padding-izquierda {
  padding-left: 0px; }

.cnp-app .home .leftContainer {
  display: none; }

.cnp-app .home .registerTextContainer {
  display: block;
  float: left;
  text-align: left;
  color: #00748C;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 14px;
  cursor: pointer; }

@media (min-width: 992px) {
  .cnp-app .home .rightContainer {
    left: 25%; } }

@media (min-width: 768px) {
  .cnp-app .home .rightContainer {
    width: 58.33333333%; }
    .cnp-app .home .rightContainer .loginButtonContainer {
      width: 100%;
      padding-left: 0px;
      padding-right: 0px; } }

@media (max-width: 767px) {
  .cnp-app .home .forgetContainer {
    text-align: center;
    margin-top: 15px;
    float: none; }
  .cnp-app .home .registerTextContainer {
    text-align: center;
    margin-top: 15px;
    float: none; } }

.homeView {
  /*display: flex;
  justify-content: center;
  align-items: center;*/
  padding: 30px 30px 0px 30px;
  min-height: calc(100vh - 130px); }
  .homeView .home .registerDot {
    margin-left: 75px; }
    .homeView .home .registerDot img {
      margin-right: 15px; }
  .homeView .home .leftContainer h1 {
    font-size: 30px;
    font-weight: bold; }
  .homeView .home .leftContainer img {
    margin: auto;
    margin-top: 30px;
    display: block;
    height: 300px;
    width: 400px; }
  .homeView .home .separator {
    float: left;
    width: 40%; }
    .homeView .home .separator hr {
      margin-top: 10px; }
  .homeView .home .separatorCenter {
    float: left;
    width: 20%;
    text-align: center;
    color: #00748C; }
  .homeView .home h2 {
    margin-top: 30px;
    font-weight: bold;
    font-size: 20px; }
  .homeView .home .inputTextLabel {
    margin-top: 20px; }
    .homeView .home .inputTextLabel .form-group {
      margin-bottom: 0px; }
  .homeView .home .loginButtonContainer {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    padding-right: 0px; }
  .homeView .home .forgetContainer {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
    text-align: right;
    color: #000000;
    margin-bottom: 5px;
    font-size: 14px;
    cursor: pointer; }
  .homeView .home .registerTextContainer {
    display: none; }

@media (min-width: 767px) {
  .cnp-app .home .form-container {
    min-height: 380px; } }

@media (max-width: 767px) {
  .home {
    padding-bottom: 50px; }
    .home .registerDot {
      margin-left: 0px; }
    .home .separator {
      float: left;
      width: 0%; }
    .home .separatorCenter {
      float: left;
      width: 100%;
      text-align: center;
      color: #00748C; } }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.overlay {
  width: 100%;
  height: 100%;
  z-index: 100000;
  background: #4a4a4a;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  opacity: .6;
  position: fixed;
  top: 0;
  left: 0; }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.modal.full {
  overflow-y: auto; }
  .modal.full .modal-header, .modal.full .modal-body {
    padding: 20px; }
  .modal.full .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: auto; }
    .modal.full .modal-dialog .modal-content {
      margin-top: 0px;
      overflow-y: auto;
      min-height: 100%;
      height: auto; }
  @media (min-width: 768px) {
    .modal.full .modal-dialog {
      width: 80%;
      height: auto;
      margin: 30px auto;
      box-shadow: 0 0 4px 0 #b5b5b5;
      border: solid 0 #FFFFFF;
      border-radius: 20px; }
      .modal.full .modal-dialog .modal-header, .modal.full .modal-dialog .modal-body {
        padding: 25px 50px; }
      .modal.full .modal-dialog .modal-content {
        min-height: auto;
        border: 0px;
        border-radius: 20px; } }
  .modal.full.new .row {
    margin-top: 30px;
    margin-left: 0px;
    margin-right: 0px; }
    .modal.full.new .row:first-child {
      margin-top: 20px; }
  @media (min-width: 768px) {
    .modal.full.new .modal-dialog {
      width: 35%;
      margin: 30px auto;
      min-width: 620px; } }

.modal-video-close-btn:after {
  background: #00748C; }

.modal .modal-dialog {
  z-index: 100001; }

.modal.modal-block {
  display: block; }

.modal .modal-dialog {
  z-index: 100001; }

.modal .modal-content {
  z-index: 100001;
  margin-top: 65px; }

.modal.absoluteModal {
  position: absolute;
  overflow: initial; }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.modal-header .modal-title {
  font-weight: bold;
  font-size: 25px;
  color: #00748C !important; }

.modal-header .close {
  font-size: 40px;
  color: #00748C;
  opacity: 1; }


.captcha {
  position: absolute;
  left: -500px;
  top: -500px;
}
@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.reactvate {
  margin-top: 20px; }
  .reactvate h1 {
    font-size: 30px;
    font-weight: bold; }
  .reactvate .inputTextLabel {
    margin-top: 25px;
    margin-bottom: 25px; }
  .reactvate .subLegendContainerRight {
    margin-top: 15px;
    float: right;
    text-align: right;
    color: #00748C;
    margin-bottom: 5px;
    cursor: pointer;
    color: #000000;
    font-size: 14px; }
  .reactvate .subLegendContainerLeft {
    margin-top: 15px;
    float: left;
    text-align: left;
    color: #00748C;
    margin-bottom: 5px;
    cursor: pointer;
    color: #000000;
    font-size: 14px; }
  .reactvate .buttonContainer {
    display: flex;
    justify-content: flex-end; }

@media (max-width: 767px) {
  .cnp-app .reactvate .subLegendContainerRight {
    margin-top: 15px;
    display: block;
    text-align: center;
    float: none; }
  .cnp-app .reactvate .subLegendContainerLeft {
    margin-top: 15px; }
  .reactvate .subLegendContainerRight {
    display: none; }
  .reactvate .subLegendContainerLeft {
    float: none;
    text-align: center; } }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.loadingApp {
  height: 100vh;
  display: flex; }

.loadingApp.noFullHeight {
  height: inherit;
  display: flex; }

.loadingApp .sk-spinner {
  width: 150px;
  height: 150px;
  color: #00748C;
  margin: auto; }

.loadingApp .sk-spinner > div {
  width: 150px;
  height: 150px; }


@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.fintupHeaderContainer .multipleFormContainer {
  min-height: calc(100vh - 117px);
  min-height: -o-calc(100vh - 117px);
  /* opera */
  /* google, safari */
  min-height: -moz-calc(100vh - 117px);
  /* firefox */ }

.fintupFullContainer .multipleFormContainer {
  min-height: calc(100vh - 0px);
  min-height: -o-calc(100vh - 0px);
  /* opera */
  /* google, safari */
  min-height: -moz-calc(100vh - 0px);
  /* firefox */ }

.fintupFullContainer.hasOperationBar .multipleFormContainer {
  padding-bottom: 100px; }

.multipleFormContainer {
  background-color: #00748C;
  border-color: #00748C;
  padding-top: 1px;
  padding-bottom: 60px;
  padding-left: 235px;
  padding-right: 235px; }
  .multipleFormContainer .mainContainer__steps {
    width: 100%;
    display: flex;
    justify-content: center; }
    .multipleFormContainer .mainContainer__steps .suitabilitySteps {
      padding: 0 10%;
      display: flex;
      flex: 1 1;
      justify-content: space-between; }
      @media (min-width: 767px) {
        .multipleFormContainer .mainContainer__steps .suitabilitySteps {
          padding: 0 20%; } }
      .multipleFormContainer .mainContainer__steps .suitabilitySteps .suitabilityStep.notActive, .multipleFormContainer .mainContainer__steps .suitabilitySteps .suitabilityStep.active {
        margin-left: 0; }
      .multipleFormContainer .mainContainer__steps .suitabilitySteps .clearAll {
        display: none; }

.multipleFormContainer .step-container {
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  outline: 0;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  margin-top: 25px; }

.multipleFormContainer h2 img {
  cursor: pointer; }

.multipleFormContainer .multipleFormContainerTitle {
  margin-bottom: 0px; }

.multipleFormContainerWidget h2.multipleFormContainerTitle {
  width: 100%;
  background-color: #3f96a8;
  margin-top: 0px;
  margin-bottom: 15px;
  padding-top: 20px;
  padding-bottom: 20px; }

@media (max-width: 1250px) {
  .multipleFormContainer {
    padding-left: 45px;
    padding-right: 45px;
    padding-top: 1px; }
  .multipleFormContainer .mainContainer h2 {
    text-align: center; }
  .multipleFormContainer .step-container {
    margin-left: 0px;
    margin-right: 0px; }
  .multipleFormContainer .content {
    padding-left: 35px;
    padding-right: 35px; }
  .multipleFormContainer .content h2 {
    margin-top: 20px; }
  .multipleFormContainer .buttonContainer {
    text-align: center;
    margin-right: 0px; } }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.miniTestStep0 .buttons {
  margin: auto; }

.miniTestStep0 div.estimated-time {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px; }
  .miniTestStep0 div.estimated-time img {
    margin-right: 10px; }
  .miniTestStep0 div.estimated-time span {
    letter-spacing: 0.2px;
    font-size: 14px; }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
/*
  * Customize style div container FintupButtonIconSelectable
  */
.fintupButtonIconSelectable {
  width: 150px;
  height: 150px;
  border-radius: 20px;
  box-shadow: 2px 2px 5px 0 #e6e6e6;
  border: solid 1px #e6e6e6;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  padding: 10px; }

:not(.noButton).fintupButtonIconSelectable:hover {
  cursor: pointer;
  border: 2px solid #000000;
  border-radius: 20px;
  box-shadow: 2px 2px 5px 0 #e6e6e6;
  border: solid 2px #52d2bc;
  background-color: #52d2bc1f; }

/*
  * Customize style text FintupButtonIconSelectable
  */
.textButtonIconSelectable {
  font-weight: bold;
  font-size: 20px;
  color: #00748C;
  margin-bottom: 0;
  text-align: center; }

/*
  * Customize style icon FintupButtonIconSelectable
  */
.iconButtonIconSelectable {
  text-align: center;
  color: #7FB9C5;
  margin: auto;
  display: block; }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.fintupSlider h1 {
  font-size: 15px; }

.fintupSlider .titleValue {
  margin-left: 1rem; }

.fintupSlider .rangeslider .rangeslider__handle-tooltip {
  width: 100px; }

.fintupSlider.withLabels {
  margin-bottom: 45px; }

.fintupSlider.withoutImageLabels .rangeslider__labels .rangeslider__label-item {
  top: -40px; }

.fintupSlider .rangeslider__handle {
  z-index: 15;
  background-color: #52d2bc; }

.fintupSlider .rangeslider__labels {
  margin-top: 10px; }

.fintupSlider .rangeslider__labels .rangeslider__label-item {
  top: -50px;
  font-size: 15px; }

.fintupSlider .rangeslider-horizontal {
  height: 2px;
  background-color: #b5b5b5;
  /*box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.4);*/ }
  .fintupSlider .rangeslider-horizontal .rangeslider__fill {
    background-color: #52d2bc;
    /*box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.4);
      -webkit-box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.4);*/ }
  .fintupSlider .rangeslider-horizontal .rangeslider__handle {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    top: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
    box-shadow: 0 0px 0px transparent, 0 0px 0px transparent;
    transition: box-shadow ease .3s; }
    .fintupSlider .rangeslider-horizontal .rangeslider__handle:after {
      width: 0px; }
    .fintupSlider .rangeslider-horizontal .rangeslider__handle:focus {
      outline: -webkit-focus-ring-color auto 0px;
      outline-color: -webkit-focus-ring-color;
      outline-style: auto;
      outline-width: 0px; }

.fintupSlider .rangeslider,
.fintupSlider .rangeslider .rangeslider__fill {
  display: block;
  box-shadow: inset 0 1px 3px transparent; }

.fintupSlider.changing .rangeslider-horizontal .rangeslider__handle {
  border-radius: 30px;
  top: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  box-shadow: 0 0px 4px #52d2bc, 0 0px 4px #52d2bc; }

/* Extra small devices (767px and down) */
@media (max-width: 767px) {
  .fintupSlider .rangeslider__labels .rangeslider__label-item {
    font-size: 12px; } }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.sliderLabel .verticalLine {
  height: 40px;
  /* border-left: 6px solid green; */ }

.sliderLabel .verticalLineContent {
  width: 1px;
  margin: 0 auto;
  background-color: #e6e6e6;
  height: 100%; }

.sliderLabel.withoutImage .verticalLineContent {
  margin-top: 10px;
  margin-right: auto;
  margin-bottom: 18px;
  margin-left: auto; }

.sliderLabel img {
  margin-bottom: 5px;
  width: 15px; }

.widgetSlider .sliderLabel .sliderLabelText {
  color: #000000;
  font-size: 14px; }

.sliderLabel.withoutImageAndBar {
  margin-top: 65px; }


@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
/*
* Visual footer always at the bottom 
*/
.fintup.fintupFullContainer {
  position: relative;
  min-height: 100vh; }
  .fintup.fintupFullContainer .appContent {
    padding-bottom: 50px; }
  .fintup.fintupFullContainer .footer-bottom {
    position: absolute;
    bottom: 0;
    width: 100%; }
  @media (max-width: 767px) {
    .fintup.fintupFullContainer .appContent {
      padding-bottom: 90px; } }
  @media (min-width: 768px) {
    .fintup.fintupFullContainer .footer-bottom .container {
      width: 100%; } }

.miniTestView {
  /*
  * Mobile
  */ }
  .miniTestView .rtf {
    bottom: 65px !important; }
  .miniTestView .multipleFormContainer {
    padding: 0;
    min-height: auto;
    border: 0;
    background-color: #FFFFFF; }
    .miniTestView .multipleFormContainer .multipleFormContainerTitle {
      margin-left: 0px;
      margin-top: 0px;
      padding-left: 25px;
      width: 100%;
      height: 80px;
      text-align: left;
      display: flex;
      align-items: center;
      background: #00748C; }
    .miniTestView .multipleFormContainer .step-container {
      margin-top: 0px;
      border-radius: 0;
      box-shadow: none;
      border: 0; }
  .miniTestView .content {
    max-width: 1200px;
    font-family: 'Lato';
    width: 100%;
    margin: auto;
    padding-top: 15px !important;
    /*
    * Tabs
    */
    /*
    * Customize style FintupSlider component
    */ }
    .miniTestView .content .m-auto {
      margin: auto; }
    .miniTestView .content .text-center {
      text-align: center !important; }
    .miniTestView .content .marked {
      color: #00748C;
      font-weight: bold; }
    .miniTestView .content .row-no-padding [class*="col-"] {
      padding-left: 0 !important;
      padding-right: 0 !important; }
    .miniTestView .content .titleMini {
      font-size: 14px; }
    .miniTestView .content .titleMiniSmall {
      font-size: 8px; }
    .miniTestView .content .contentTabs {
      position: -webkit-sticky;
      /* Safari */
      position: sticky;
      top: 15px;
      z-index: 1000; }
      .miniTestView .content .contentTabs .miniTestTabs {
        height: 55px;
        margin: auto;
        border-radius: 20px;
        box-shadow: 0 0 4px 0 #b5b5b5;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border: solid 0 #FFFFFF;
        background: #FFFFFF;
        /*
        * Title
        */
        /*
        * Subtitle
        */ }
        .miniTestView .content .contentTabs .miniTestTabs .miniTestTabsElement {
          cursor: pointer;
          padding: 4px; }
        .miniTestView .content .contentTabs .miniTestTabs > div {
          width: 100%;
          border-left: dotted 0.5px #9b9b9b; }
          .miniTestView .content .contentTabs .miniTestTabs > div:first-child {
            border-left: none; }
        .miniTestView .content .contentTabs .miniTestTabs p {
          font-weight: normal;
          margin-bottom: 0px; }
        .miniTestView .content .contentTabs .miniTestTabs p:first-child {
          font-size: 14px;
          letter-spacing: 0.4px;
          color: #000000; }
        .miniTestView .content .contentTabs .miniTestTabs p:not(:first-child) {
          font-size: 8px;
          color: #00748C; }
      .miniTestView .content .contentTabs .buttonContainer {
        position: absolute;
        right: 15px;
        top: 3px; }
    .miniTestView .content .row {
      margin-left: 0;
      margin-right: 0;
      margin-top: 22px !important; }
      .miniTestView .content .row .elements {
        display: flex;
        justify-content: center; }
    .miniTestView .content h3 {
      font-family: 'Lato';
      color: #000000;
      font-weight: bold;
      letter-spacing: 0.4px;
      line-height: 1.3; }
    .miniTestView .content div.investStyle {
      width: 100px;
      height: 100px;
      border-radius: 20px;
      box-shadow: 2px 2px 5px 0 #e6e6e6;
      border: solid 1px #e6e6e6;
      background-color: #FFFFFF;
      font-size: 36px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #000000;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column; }
      .miniTestView .content div.investStyle p {
        margin-top: 3px;
        font-size: 16px;
        font-weight: bold;
        color: #00748C;
        margin-bottom: 0px; }
    .miniTestView .content h4.investYears {
      width: 100px;
      height: 100px;
      border-radius: 20px;
      box-shadow: 2px 2px 5px 0 #e6e6e6;
      border: solid 1px #e6e6e6;
      background-color: #FFFFFF;
      font-size: 36px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #000000;
      display: flex;
      justify-content: center;
      align-items: center; }
    .miniTestView .content h4.typeContribution {
      text-align: left;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold; }
    .miniTestView .content h4.inputNumber {
      width: 125px;
      height: 50px;
      border-radius: 15px;
      box-shadow: 2px 2px 5px 0 #e6e6e6;
      border: solid 1px #e6e6e6;
      background-color: #FFFFFF;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 24px;
      margin: 0px auto;
      padding-right: 6px;
      font-weight: bold;
      float: right;
      /*
      * Currency
      */ }
      .miniTestView .content h4.inputNumber span {
        margin-left: 3px; }
    .miniTestView .content span.subtitle-text {
      color: #00748C;
      letter-spacing: 0.2px;
      font-size: 14px; }
      .miniTestView .content span.subtitle-text-error {
        color: #FB4D6D; }
    .miniTestView .content .slider .fintupSlider .rangeslider-horizontal {
      height: 5px;
      background-color: #e6e6e6; }
  @media (max-width: 575.98px) {
    .miniTestView .content .miniTestTabs {
      width: 100%; }
    .miniTestView .multipleFormContainer .step-container {
      height: 100%; } }
  @media (min-width: 768px) {
    .miniTestView .content {
      width: 50%; } }
  @media (max-width: 1250px) {
    .miniTestView .content {
      padding-left: 15px;
      padding-right: 15px; } }
  @media (max-width: 900px) {
    .miniTestView .content .contentTabs .buttonContainer {
      position: relative;
      top: 0px;
      right: 0px;
      margin-top: 10px; } }
  .miniTestView .miniTestStepButtonContainer {
    display: flex;
    justify-content: center;
    margin-top: 35px;
    margin-bottom: 20px;
    margin-left: 1%;
    margin-right: 1%; }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.miniTestStep3 .content .row > [class*="col-"] {
  padding-left: 0 !important;
  padding-right: 0 !important; }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.miniTestStep4 .content .investStyle img {
  width: 55px; }

.miniTestStep4 .content .slider div.labels {
  display: flex;
  justify-content: space-between; }
  .miniTestStep4 .content .slider div.labels span {
    font-size: 14px;
    color: #00748C;
    letter-spacing: 0.2px; }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.miniTestStep5 .content .containerInvest {
  margin: auto;
  margin-top: 20px !important;
  display: flex;
  justify-content: space-around; }
  .miniTestStep5 .content .containerInvest .investStyle:first-child {
    margin-right: 10px; }
  .miniTestStep5 .content .containerInvest .investStyle {
    position: relative;
    height: 85px;
    cursor: pointer; }
    .miniTestStep5 .content .containerInvest .investStyle p {
      font-weight: normal; }
    .miniTestStep5 .content .containerInvest .investStyle p:first-child {
      font-size: 20px;
      letter-spacing: 0.67px;
      color: #000000; }
    .miniTestStep5 .content .containerInvest .investStyle p:not(:first-child) {
      font-size: 14px; }
    .miniTestStep5 .content .containerInvest .investStyle img {
      width: 18px;
      height: 18px;
      border: solid 0.5px #52d2bc;
      background-color: #52d2bc;
      border-radius: 50%;
      padding-top: 2px;
      padding-bottom: 3px;
      position: absolute;
      top: -5px;
      right: -5px; }
  .miniTestStep5 .content .containerInvest .investObjective, .miniTestStep5 .content .containerInvest .investInvestor {
    width: 175px; }
  .miniTestStep5 .content .containerInvest .investTime {
    width: 81.3px; }
  .miniTestStep5 .content .containerInvest .investMoney {
    width: 253px;
    display: flex;
    flex-direction: row;
    justify-content: space-around; }

@media (min-width: 768px) {
  .miniTestStep5 .content .containerInvest {
    width: 75%; } }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.limitSize {
  max-width: 1200px;
  margin: auto !important; }

.miniTestStep6 .contentFull {
  max-width: 100%;
  width: 100%;
  padding: 0; }
  .miniTestStep6 .contentFull .miniTestTabs {
    max-width: 1200px;
    width: 95%;
    margin: auto; }

@media (min-width: 768px) {
  .miniTestStep6 .content .miniTestTabs {
    width: 50%; } }

.miniTestProductLanding {
  /*
  * General styles
  */
  /*
  * END General styles
  */
  /** 
  * Mobile phone - tablet
  */ }
  .miniTestProductLanding .row {
    padding-top: 25px !important; }
  .miniTestProductLanding h2, .miniTestProductLanding h3 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    font-weight: bold !important;
    text-align: center; }
  .miniTestProductLanding p {
    margin-bottom: 0 !important; }
  .miniTestProductLanding h3.subtitle {
    margin-bottom: 50px !important;
    font-size: 25px !important; }
  .miniTestProductLanding .buttonClass {
    font-size: 15px;
    font-weight: bold;
    text-transform: none;
    letter-spacing: 0.4px; }
  .miniTestProductLanding .icon-wrapper {
    letter-spacing: 0.4px;
    font-size: 20px;
    display: inline-block;
    width: 25px;
    letter-spacing: 0.7px;
    margin-right: 7px; }
    .miniTestProductLanding .icon-wrapper .fa {
      font-size: 21px; }
    .miniTestProductLanding .icon-wrapper .fa-check {
      color: #52d2bc; }
    .miniTestProductLanding .icon-wrapper .fa-times {
      color: #EF6776; }
  .miniTestProductLanding .text {
    text-align: justify;
    font-size: 18px;
    letter-spacing: 0.4px;
    line-height: 30px;
    font-weight: 300; }
    .miniTestProductLanding .text strong {
      font-size: 21px;
      display: block; }
  .miniTestProductLanding table.tableModal {
    margin: auto;
    margin-top: 0px; }
    .miniTestProductLanding table.tableModal tr th {
      color: #FFFFFF;
      background-color: #00748C; }
    .miniTestProductLanding table.tableModal tr td, .miniTestProductLanding table.tableModal tr th {
      text-align: center;
      padding: 0px;
      padding-left: 30px;
      padding-right: 30px; }
  .miniTestProductLanding .col-list-table {
    padding: 0px !important; }
  .miniTestProductLanding .recommendation {
    padding-top: 0px !important;
    height: 75px;
    background: linear-gradient(rgba(223, 237, 240, 0.5), rgba(223, 237, 240, 0.5));
    background-position: 0px 38%;
    background-size: cover;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s; }
    .miniTestProductLanding .recommendation .limitSize {
      height: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap; }
      .miniTestProductLanding .recommendation .limitSize .investTypeContainer {
        text-align: left; }
        .miniTestProductLanding .recommendation .limitSize .investTypeContainer h2 {
          font-size: 25px;
          text-align: center;
          letter-spacing: 0.4px; }
        .miniTestProductLanding .recommendation .limitSize .investTypeContainer p {
          font-size: 16px; }
        .miniTestProductLanding .recommendation .limitSize .investTypeContainer .buttonContainer {
          text-align: left; }
          .miniTestProductLanding .recommendation .limitSize .investTypeContainer .buttonContainer .buttonClass {
            margin-top: 15px; }
      .miniTestProductLanding .recommendation .limitSize .profitabilityContainer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        /*
        TODO: remove when profitability values appear
        div:first-child{
          margin-right: 50px;
        }*/ }
        .miniTestProductLanding .recommendation .limitSize .profitabilityContainer div h3 {
          text-align: center;
          color: #00748C;
          font-size: 50px;
          margin-top: 0; }
        .miniTestProductLanding .recommendation .limitSize .profitabilityContainer div p {
          font-size: 16px;
          letter-spacing: 0.4px; }
  .miniTestProductLanding .videoText {
    width: 80%; }
    .miniTestProductLanding .videoText .videoContainer {
      height: 450px;
      margin-bottom: 20px; }
  .miniTestProductLanding .external {
    margin-top: 50px;
    margin-bottom: 50px; }
    .miniTestProductLanding .external .buttonClass {
      margin-bottom: 20px; }
    .miniTestProductLanding .external a {
      color: #00748C;
      text-decoration: underline; }
  .miniTestProductLanding .information {
    padding-top: 0px !important; }
    .miniTestProductLanding .information h3.subtitle {
      padding: 15px; }
    .miniTestProductLanding .information .informationContainer .contentGeneralSlider {
      height: auto !important; }
      .miniTestProductLanding .information .informationContainer .contentGeneralSlider .horizontalSlider___281Ls {
        position: relative;
        height: auto !important;
        width: auto; }
    .miniTestProductLanding .information .informationContainer .navigationSlider {
      display: flex;
      justify-content: center;
      align-items: center; }
      .miniTestProductLanding .information .informationContainer .navigationSlider .buttonSlideContainer {
        background: transparent;
        border: 0; }
        .miniTestProductLanding .information .informationContainer .navigationSlider .buttonSlideContainer .buttonSlide {
          width: 40px;
          height: 40px;
          background: transparent;
          border: 1px solid #52d2bc;
          color: #52d2bc;
          font-size: 18px;
          padding: 8px; }
      .miniTestProductLanding .information .informationContainer .navigationSlider ul {
        align-items: center !important; }
      .miniTestProductLanding .information .informationContainer .navigationSlider .titleSlide {
        font-size: 25px;
        margin-top: 10px; }
      .miniTestProductLanding .information .informationContainer .navigationSlider .subtitleSlide {
        font-size: 20px;
        color: #00748C;
        margin-bottom: 10px; }
    .miniTestProductLanding .information .informationContainer .dotReferencesSlide {
      margin: 14px;
      margin-top: 8px; }
      .miniTestProductLanding .information .informationContainer .dotReferencesSlide button {
        height: 10px;
        width: 10px;
        background: transparent;
        border-radius: 50%;
        border: solid 1px #979797;
        padding: 0;
        margin-right: 14px; }
        .miniTestProductLanding .information .informationContainer .dotReferencesSlide button:last-child {
          margin-right: 0px; }
      .miniTestProductLanding .information .informationContainer .dotReferencesSlide button.carousel__dot--selected {
        border: 1px solid #52d2bc;
        background: #52d2bc; }
    .miniTestProductLanding .information .informationContainer .contentSlider .containerSlide {
      height: auto; }
      .miniTestProductLanding .information .informationContainer .contentSlider .containerSlide .contentSlide {
        padding-right: 30px; }
        .miniTestProductLanding .information .informationContainer .contentSlider .containerSlide .contentSlide img.full {
          width: 100%;
          height: 100%;
          max-width: 100%;
          max-height: 100%;
          margin-bottom: 20px; }
        .miniTestProductLanding .information .informationContainer .contentSlider .containerSlide .contentSlide p {
          margin-bottom: 20px; }
        .miniTestProductLanding .information .informationContainer .contentSlider .containerSlide .contentSlide .textFooterSlider {
          text-align: center;
          font-style: italic;
          font-size: 16px; }
        .miniTestProductLanding .information .informationContainer .contentSlider .containerSlide .contentSlide ul {
          list-style-type: disc; }
        .miniTestProductLanding .information .informationContainer .contentSlider .containerSlide .contentSlide .elements {
          justify-content: space-around;
          margin-top: 30px;
          margin-bottom: 30px; }
          .miniTestProductLanding .information .informationContainer .contentSlider .containerSlide .contentSlide .elements div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
            width: 100px; }
            .miniTestProductLanding .information .informationContainer .contentSlider .containerSlide .contentSlide .elements div img {
              width: 60px;
              margin-bottom: 10px; }
            .miniTestProductLanding .information .informationContainer .contentSlider .containerSlide .contentSlide .elements div p:first-child {
              font-size: 16px; }
            .miniTestProductLanding .information .informationContainer .contentSlider .containerSlide .contentSlide .elements div p:last-child {
              margin-top: 10px;
              font-size: 18px; }
    .miniTestProductLanding .information .advantagesContainer div {
      width: 100%;
      margin: auto;
      padding: 10px 10px 10px;
      border-radius: 35px; }
      .miniTestProductLanding .information .advantagesContainer div h4 {
        font-weight: bold;
        font-size: 25px;
        color: #00748C;
        margin-top: 0px;
        margin-bottom: 20px; }
      .miniTestProductLanding .information .advantagesContainer div .text {
        text-align: left; }
      .miniTestProductLanding .information .advantagesContainer div p {
        text-align: left;
        display: flex;
        line-height: 25px !important;
        padding-bottom: 10px; }
    .miniTestProductLanding .information .advantagesContainer .hrefReviews img {
      max-width: 100%;
      padding: 0px 10px;
      height: 27px; }
  .miniTestProductLanding .comparator {
    padding: 15px;
    margin: auto;
    text-align: left;
    padding-bottom: 25px; }
    .miniTestProductLanding .comparator h3.subtitle {
      margin-bottom: 25px !important; }
    .miniTestProductLanding .comparator .comparatorContainer {
      width: 80%;
      display: flex;
      flex-wrap: wrap;
      margin: auto;
      justify-content: space-between;
      margin-bottom: 25px; }
      .miniTestProductLanding .comparator .comparatorContainer .comparatorElement {
        margin-top: 25px;
        width: 250px;
        height: 300px;
        box-shadow: 2px 2px 5px 0 #e6e6e6;
        border: solid 1px #e6e6e6;
        background-color: #FFFFFF;
        border-radius: 30px;
        border-width: 2px;
        transition: background 0.3s, border 2s, border-radius 2s, box-shadow 2s;
        padding: 20px; }
        .miniTestProductLanding .comparator .comparatorContainer .comparatorElement.active {
          background-color: #EFFCFF;
          border: #00748C solid 2px;
          box-shadow: 0px 0px 25px 0px #e6e6e6; }
        .miniTestProductLanding .comparator .comparatorContainer .comparatorElement h4 {
          color: #00748C;
          font-size: 20px; }
        .miniTestProductLanding .comparator .comparatorContainer .comparatorElement p {
          display: flex;
          align-items: flex-start; }
          .miniTestProductLanding .comparator .comparatorContainer .comparatorElement p span {
            text-align: left; }
    .miniTestProductLanding .comparator .text {
      width: 80%;
      margin: auto; }
  .miniTestProductLanding .doubts {
    margin-bottom: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 280px;
    background: linear-gradient(rgba(223, 237, 240, 0.5), rgba(223, 237, 240, 0.5)), url(https://fintup.com/wp-content/uploads/2019/04/fintup-testInversor-squashed.jpg);
    background-position: top center;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s; }
    .miniTestProductLanding .doubts p {
      font-weight: bold;
      font-size: 25px;
      color: #000000;
      letter-spacing: 0.4px;
      line-height: 1.33; }
    .miniTestProductLanding .doubts .buttonClass {
      margin-top: 30px; }
  .miniTestProductLanding .videoWidget {
    z-index: 99;
    position: fixed;
    right: 35px;
    bottom: 60px;
    width: 100%;
    max-width: 336px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer; }
    .miniTestProductLanding .videoWidget .tooltip {
      position: relative;
      box-shadow: 2px 2px 5px 0 #e6e6e6;
      background-color: #00748C;
      border-radius: 30px;
      padding: 8px 24px;
      opacity: .8;
      -webkit-transform: none;
              transform: none; }
      .miniTestProductLanding .videoWidget .tooltip .text {
        color: #FFFFFF;
        text-align: right;
        line-height: 25px; }
      .miniTestProductLanding .videoWidget .tooltip:after {
        display: block;
        content: '';
        position: absolute;
        top: 50%;
        left: calc(100% - 1px);
        -webkit-transform: translate3d(0, -50%, 0);
                transform: translate3d(0, -50%, 0);
        width: 0;
        height: 0;
        margin-left: -3px;
        border-style: solid;
        border-width: 12px 0 12px 8px;
        border-color: transparent transparent transparent #00748C; }
    .miniTestProductLanding .videoWidget .fabAvatar {
      position: relative;
      margin-left: 15px; }
      .miniTestProductLanding .videoWidget .fabAvatar .managerImage .imageFrame {
        height: auto;
        width: auto;
        align-items: center;
        display: flex;
        justify-content: center;
        background: transparent; }
        .miniTestProductLanding .videoWidget .fabAvatar .managerImage .imageFrame img.mainImage {
          width: 75px;
          height: 75px;
          border-radius: 75px;
          vertical-align: middle;
          border: 0; }
    .miniTestProductLanding .videoWidget .dismissVideoWidget {
      border: 0;
      padding: 0;
      background: transparent;
      position: absolute;
      top: -10px;
      right: -15px;
      font-size: 25px; }
  .miniTestProductLanding .modalBodyReport {
    width: 50%;
    margin: auto; }
    .miniTestProductLanding .modalBodyReport .radioButton {
      margin-top: 20px;
      margin-bottom: 15px !important;
      text-align: left; }
  @media (max-width: 767px) {
    .miniTestProductLanding {
      /*
    * General styles
    */
      /*
    * END General styles
    */ }
      .miniTestProductLanding .recommendation {
        padding: 25px !important;
        height: auto; }
        .miniTestProductLanding .recommendation .limitSize .investTypeContainer {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column; }
        .miniTestProductLanding .recommendation .limitSize .profitabilityContainer {
          padding: 0;
          justify-content: space-around; }
          .miniTestProductLanding .recommendation .limitSize .profitabilityContainer div:first-child {
            margin-right: 0px; }
          .miniTestProductLanding .recommendation .limitSize .profitabilityContainer div h3 {
            font-size: 40px; }
          .miniTestProductLanding .recommendation .limitSize .profitabilityContainer div p {
            font-size: 14px; }
      .miniTestProductLanding .videoText {
        width: 100%;
        padding: 15px; }
        .miniTestProductLanding .videoText .videoContainer {
          height: 450px;
          margin-bottom: 20px; }
      .miniTestProductLanding .information h3.subtitle {
        padding: 15px; }
      .miniTestProductLanding .information .informationContainer .contentGeneralSlider {
        margin-bottom: 30px; }
        .miniTestProductLanding .information .informationContainer .contentGeneralSlider .contentSlide {
          padding-right: 0px !important; }
      .miniTestProductLanding .information .advantagesContainer {
        padding: 0; }
        .miniTestProductLanding .information .advantagesContainer div {
          padding-top: 50px;
          width: 100%;
          border-radius: 0; }
      .miniTestProductLanding .comparator .comparatorContainer {
        justify-content: center; }
        .miniTestProductLanding .comparator .comparatorContainer .comparatorElement {
          margin: 10px; }
      .miniTestProductLanding .comparator div.text {
        width: 100%; }
      .miniTestProductLanding .modalBodyReport {
        width: 100%; } }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.openerModal {
  cursor: pointer;
  color: #428bca;
  font-weight: 400; }
  .openerModal:hover {
    color: #428bca;
    text-decoration: underline; }
  .openerModal span {
    display: inline; }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.managerImage {
  text-align: center; }
  .managerImage .containerImage {
    text-align: -webkit-center; }
  .managerImage .imageFrame {
    height: 130px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    width: 130px;
    position: relative;
    /*&.arrowBottom:after {
      content: " ";
      position: absolute;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #edeee6 transparent transparent transparent;
    }*/
    /*&.arrowRight:after {
        content:"";  
        position:absolute;
        height:0;
        width:0;
        left:100%;
        top: 50%;
        margin-left: -0px;
        margin-top: -5px;
        border:5px solid transparent;
        border-left: 5px solid #edeee6
    }*/ }
    .managerImage .imageFrame .mainImage {
      width: 126px;
      height: 126px; }
  .managerImage.theme-new {
    display: flex;
    justify-content: start; }
    .managerImage.theme-new .imageFrame {
      height: 50px;
      width: 50px;
      position: relative; }
    .managerImage.theme-new .mainImage {
      width: 75px;
      height: 75px;
      border: 2px solid #52d2bc;
      border-radius: 50%; }
      @media (max-width: 768px) {
        .managerImage.theme-new .mainImage {
          width: 50px;
          height: 50px; } }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
/**
* New styles of components
*/
*.theme-new {
  font-family: 'Lato' !important;
  font-size: 18px !important; }
  *.theme-new label, *.theme-new .form-control {
    font-family: 'Lato' !important;
    font-size: 18px !important; }
  *.theme-new .react-date-picker__wrapper {
    font-family: 'Lato';
    border: none;
    border-bottom: 2px solid #52d2bc;
    box-shadow: none;
    min-width: 180px;
    margin-bottom: 15px; }
    *.theme-new .react-date-picker__wrapper .react-date-picker__inputGroup {
      display: flex;
      justify-content: center;
      align-items: center; }
    *.theme-new .react-date-picker__wrapper input::-webkit-input-placeholder {
      font-weight: 400;
      color: #52d2bc5f; }
    *.theme-new .react-date-picker__wrapper input::placeholder, *.theme-new .react-date-picker__wrapper input::-webkit-input-placeholder {
      font-weight: 400;
      color: #52d2bc5f; }
    *.theme-new .react-date-picker__wrapper .react-date-picker__inputGroup__divider {
      font-size: 30px;
      font-weight: 500;
      color: #52d2bc;
      padding: 0px 10px; }

.radioButton [type="radio"]:checked,
.radioButton [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px; }

.radioButton [type="radio"]:checked + label,
.radioButton [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #2d2d2d;
  font-weight: 400;
  font-family: 'Ubuntu';
  font-size: 16px; }

.radioGroup .radioButton [type="radio"]:checked + label,
.radioGroup .radioButton [type="radio"]:not(:checked) + label {
  font-size: 15px;
  color: #575656; }

.radioButton [type="radio"]:checked + label:before,
.radioButton [type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff; }

.radioButton [type="radio"]:checked + label:after,
.radioButton [type="radio"]:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #52d2bc;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  transition: all 0.2s ease; }

.radioButton [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0); }

.radioButton [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1); }

.radioButton.theme-new label {
  padding-left: 38px !important; }

.radioButton.theme-new label {
  color: #000000 !important;
  font-weight: 300 !important; }

.radioButton.theme-new label:before {
  width: 22px !important;
  height: 22px !important; }

.radioButton.theme-new label:after {
  content: url("/img/fintup-check.svg") !important;
  position: absolute;
  top: 1px !important;
  left: 1px !important;
  width: 20px !important;
  height: 20px !important;
  border: 1px solid #FFFFFF;
  border-radius: 100%;
  background: #52d2bc;
  font-size: 15px !important;
  display: flex;
  justify-content: center;
  align-items: center; }

.radioButton.theme-new label:before, .radioButton.theme-new label:after {
  margin-top: -2px; }

.radioButton.theme-new :checked + label {
  font-weight: bold !important; }

.radioButton.theme-new :checked + label:before,
.radioButton.theme-new :not(:checked) + label:before {
  border-color: #52d2bc; }

.radioButton.theme-new :checked + label:before {
  background: #52d2bc; }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.fintupFullWidget .registerView .singleFormContainer.mainContainer {
  min-height: inherit;
  background-color: inherit;
  border-color: inherit;
  padding-bottom: 0px; }

.fintupFullWidget .registerView .singleFormContainer.double .form-container {
  margin-left: inherit;
  margin-right: inherit;
  padding-top: inherit;
  padding-left: inherit;
  padding-bottom: inherit;
  padding-right: inherit; }

.fintupFullWidget .registerView .singleFormContainer .form-container {
  background-color: inherit;
  -webkit-background-clip: inherit;
  background-clip: inherit;
  border: inherit;
  border: inherit;
  border-radius: inherit;
  outline: inherit;
  box-shadow: inherit;
  margin-left: inherit;
  margin-right: inherit;
  margin-top: inherit;
  padding: inherit; }

.registerView {
  padding-bottom: 30px; }
  .registerView .outText {
    color: #FFFFFF;
    margin-top: 25px; }
  .registerView .singleFormContainer.mainContainer {
    padding-top: 0px; }
  .registerView .separator {
    float: left;
    width: 40%; }
    .registerView .separator hr {
      margin-top: 10px; }
  .registerView .forgetContainer {
    color: #00748C;
    margin-top: 10px;
    margin-bottom: 25px;
    text-align: right;
    cursor: pointer; }
  .registerView .separatorCenter {
    float: left;
    width: 20%;
    text-align: center;
    color: #00748C; }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.fintupCheckbox.titleCheckbox.checkbox label {
  font-weight: 700; }

.fintupCheckbox.titleCheckbox.checkbox {
  margin-top: 3px; }

.fintupCheckbox.checkbox label {
  padding-left: 0px; }

.fintupCheckbox.checkbox label:after,
.fintupCheckbox.radio label:after {
  content: '';
  display: table;
  clear: both; }

.fintupCheckbox.checkbox .cr,
.fintupCheckbox.radio .cr {
  position: relative;
  border: 1px solid #a9a9a9;
  border-radius: .25em;
  width: 1.3em;
  height: 1.3em;
  float: left;
  margin-right: .5em; }

.fintupCheckbox.radio .cr {
  border-radius: 50%; }

.fintupCheckbox.checkbox .cr .cr-icon,
.fintupCheckbox.radio .cr .cr-icon {
  position: absolute;
  font-size: 10px;
  line-height: 0;
  top: 50%;
  left: calc(50% - 5px); }

.fintupCheckbox.radio .cr .cr-icon {
  margin-left: 0.04em; }

.fintupCheckbox.checkbox label input[type="checkbox"],
.fintupCheckbox.radio label input[type="radio"] {
  display: none; }

.fintupCheckbox.checkbox label input[type="checkbox"] + .cr > .cr-icon,
.fintupCheckbox.radio label input[type="radio"] + .cr > .cr-icon {
  -webkit-transform: scale(3) rotateZ(-20deg);
          transform: scale(3) rotateZ(-20deg);
  opacity: 0;
  transition: all .3s ease-in; }

.fintupCheckbox.checkbox label input[type="checkbox"]:checked + .cr > .cr-icon,
.fintupCheckbox.radio label input[type="radio"]:checked + .cr > .cr-icon {
  -webkit-transform: scale(1) rotateZ(0deg);
          transform: scale(1) rotateZ(0deg);
  opacity: 1;
  color: #52d2bc; }

.fintupCheckbox.checkbox.saving label input[type="checkbox"]:checked + .cr > .cr-icon,
.fintupCheckbox.radio.saving label input[type="radio"]:checked + .cr > .cr-icon {
  color: #7FB9C5; }

.fintupCheckbox.checkbox.saving,
.fintupCheckbox.radio.saving {
  background-color: #FFFFFF; }

.fintupCheckbox.checkbox.pensionPlan label input[type="checkbox"]:checked + .cr > .cr-icon,
.fintupCheckbox.radio.pensionPlan label input[type="radio"]:checked + .cr > .cr-icon {
  color: #3F96A8; }

.fintupCheckbox.checkbox.pensionPlan,
.fintupCheckbox.radio.pensionPlan {
  background-color: #FFFFFF; }

.fintupCheckbox.checkbox label input[type="checkbox"]:disabled + .cr,
.fintupCheckbox.radio label input[type="radio"]:disabled + .cr {
  opacity: .5; }

.fintupCheckbox.theme-new label input[type="checkbox"]:checked + span.labelText {
  font-weight: bold !important;
  color: red !important; }


@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.registerCode .inputTextLabel {
  width: 80%;
  margin: auto; }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.miniTestRegister {
  margin-top: 20px; }
  .miniTestRegister .headerStepsBlock img {
    width: 210px;
    height: 125px; }
  .miniTestRegister .headerStepsBlock h4 {
    color: #00748C;
    font-size: 20px;
    font-weight: bold; }
  .miniTestRegister .headerStepsBlock p {
    font-size: 14px;
    line-height: 1.67;
    letter-spacing: 0.32px; }
  .miniTestRegister .headerStepsBlock .previous {
    cursor: pointer; }
  .miniTestRegister .headerStepsBlock .breadcrumbBlock {
    margin-bottom: 20px; }
    .miniTestRegister .headerStepsBlock .breadcrumbBlock .crumb {
      width: 150px;
      /*
        * Mobile
        */
      height: 40px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 33px; }
      @media (max-width: 767px) {
        .miniTestRegister .headerStepsBlock .breadcrumbBlock .crumb {
          width: 90px; } }
      .miniTestRegister .headerStepsBlock .breadcrumbBlock .crumb .icon-wrapper .fa {
        font-size: 18px; }
      .miniTestRegister .headerStepsBlock .breadcrumbBlock .crumb .icon-wrapper, .miniTestRegister .headerStepsBlock .breadcrumbBlock .crumb .fintupIcons {
        margin-left: 10px;
        font-weight: 300;
        /*
          * Mobile
          */ }
        @media (max-width: 767px) {
          .miniTestRegister .headerStepsBlock .breadcrumbBlock .crumb .icon-wrapper, .miniTestRegister .headerStepsBlock .breadcrumbBlock .crumb .fintupIcons {
            margin-left: 2px; } }
    .miniTestRegister .headerStepsBlock .breadcrumbBlock .bread:not(.next):after {
      position: absolute;
      content: "";
      height: 2px;
      width: calc(100% - 150px);
      margin-left: calc(150px / 2);
      top: 50%;
      z-index: 100000;
      /*
          * Mobile
          */ }
      @media (max-width: 767px) {
        .miniTestRegister .headerStepsBlock .breadcrumbBlock .bread:not(.next):after {
          width: calc(100% - 90px);
          margin-left: calc(90px / 2); } }
    .miniTestRegister .headerStepsBlock .breadcrumbBlock .bread.previous:after {
      background: #7FB9C5; }
    .miniTestRegister .headerStepsBlock .breadcrumbBlock .bread.previous .crumb {
      background-color: #FFFFFF;
      border: 1px solid #7FB9C5;
      color: #7FB9C5; }
    .miniTestRegister .headerStepsBlock .breadcrumbBlock .bread.active:after {
      background: #e6e6e6; }
    .miniTestRegister .headerStepsBlock .breadcrumbBlock .bread.active .crumb {
      background-color: #FF5200;
      border: 1px solid #FF5200;
      color: #FFFFFF;
      font-weight: bold; }
    .miniTestRegister .headerStepsBlock .breadcrumbBlock .bread.next .crumb {
      background-color: #FFFFFF;
      margin: auto;
      border: 1px solid #e6e6e6;
      color: #e6e6e6; }
  .miniTestRegister .registerBlock {
    margin-top: 20px; }
    .miniTestRegister .registerBlock h3 {
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 0px; }
    .miniTestRegister .registerBlock .formBlock .buttons {
      display: flex;
      justify-content: center;
      align-items: center; }
      .miniTestRegister .registerBlock .formBlock .buttons button, .miniTestRegister .registerBlock .formBlock .buttons .socialLogin {
        margin: 20px 40px 0px;
        /*
          * Mobile
          */ }
        @media (max-width: 767px) {
          .miniTestRegister .registerBlock .formBlock .buttons button, .miniTestRegister .registerBlock .formBlock .buttons .socialLogin {
            margin: 10px 20px 0px; } }
      .miniTestRegister .registerBlock .formBlock .buttons .socialLogin .react-google-oauth-button-login {
        width: 150px !important;
        height: 45px;
        border-radius: 30px;
        border: 2px solid #52d2bc;
        font-weight: bold;
        text-transform: uppercase; }
        .miniTestRegister .registerBlock .formBlock .buttons .socialLogin .react-google-oauth-button-login:hover {
          opacity: 1; }
        .miniTestRegister .registerBlock .formBlock .buttons .socialLogin .react-google-oauth-button-login:active {
          color: inherit;
          border: 2px solid #52d2bc;
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
        .miniTestRegister .registerBlock .formBlock .buttons .socialLogin .react-google-oauth-button-login .react-google-oauth-button-border {
          display: flex;
          justify-content: center;
          align-items: center; }
    .miniTestRegister .registerBlock .formBlock .fieldsBlock {
      margin-top: 25px; }
      .miniTestRegister .registerBlock .formBlock .fieldsBlock .buttonsFields {
        display: flex;
        justify-content: space-around;
        /*
          * Mobile
          */ }
        @media (max-width: 767px) {
          .miniTestRegister .registerBlock .formBlock .fieldsBlock .buttonsFields {
            margin-bottom: 20px; } }
    .miniTestRegister .registerBlock .formBlock .terms {
      width: 50%;
      text-align: center;
      margin-bottom: 20px;
      /*
        * Mobile
        */ }
      .miniTestRegister .registerBlock .formBlock .terms .checkboxTerm {
        margin-top: 10px;
        margin-bottom: 0px; }
        .miniTestRegister .registerBlock .formBlock .terms .checkboxTerm label {
          font-size: 14px !important; }
      @media (max-width: 767px) {
        .miniTestRegister .registerBlock .formBlock .terms {
          width: 90%; }
          .miniTestRegister .registerBlock .formBlock .terms .checkboxTerm {
            margin-top: 20px; } }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
/**
* New styles of components
*/
*.theme-new {
  font-family: 'Lato' !important;
  font-size: 18px !important; }
  *.theme-new label, *.theme-new .form-control {
    font-family: 'Lato' !important;
    font-size: 18px !important; }
  *.theme-new .react-date-picker__wrapper {
    font-family: 'Lato';
    border: none;
    border-bottom: 2px solid #52d2bc;
    box-shadow: none;
    min-width: 180px;
    margin-bottom: 15px; }
    *.theme-new .react-date-picker__wrapper .react-date-picker__inputGroup {
      display: flex;
      justify-content: center;
      align-items: center; }
    *.theme-new .react-date-picker__wrapper input::-webkit-input-placeholder {
      font-weight: 400;
      color: #52d2bc5f; }
    *.theme-new .react-date-picker__wrapper input::placeholder, *.theme-new .react-date-picker__wrapper input::-webkit-input-placeholder {
      font-weight: 400;
      color: #52d2bc5f; }
    *.theme-new .react-date-picker__wrapper .react-date-picker__inputGroup__divider {
      font-size: 30px;
      font-weight: 500;
      color: #52d2bc;
      padding: 0px 10px; }

.fintupCheckboxNew [type="checkbox"]:checked,
.fintupCheckboxNew [type="checkbox"]:not(:checked) {
  position: absolute;
  left: -9999px; }

.fintupCheckboxNew [type="checkbox"]:checked + label,
.fintupCheckboxNew [type="checkbox"]:not(:checked) + label {
  position: relative;
  cursor: pointer;
  line-height: 20px; }

.fintupCheckboxNew [type="checkbox"]:checked + label:before,
.fintupCheckboxNew [type="checkbox"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  border: 1px solid #FFFFFF;
  border-radius: 7px; }

.fintupCheckboxNew [type="checkbox"]:checked + label:after,
.fintupCheckboxNew [type="checkbox"]:not(:checked) + label:after {
  transition: all 0.2s ease; }

.fintupCheckboxNew [type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0); }

.fintupCheckboxNew [type="checkbox"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1); }

.fintupCheckboxNew.theme-new label {
  padding-left: 38px !important; }

.fintupCheckboxNew.theme-new label {
  color: #000000 !important;
  font-weight: 300; }

.fintupCheckboxNew.theme-new label:before {
  width: 22px !important;
  height: 22px !important; }

.fintupCheckboxNew.theme-new label:after {
  content: url("/img/fintup-check.svg") !important;
  position: absolute;
  top: 1px !important;
  left: 1px !important;
  width: 20px !important;
  height: 20px !important;
  border: 1px solid #FFFFFF;
  border-radius: 7px;
  background: #52d2bc;
  font-size: 15px !important;
  display: flex;
  justify-content: center;
  align-items: center; }

.fintupCheckboxNew.theme-new label:before, .fintupCheckboxNew.theme-new label:after {
  margin-top: -2px; }

.fintupCheckboxNew.theme-new.boldChecked :checked + label {
  font-weight: bold; }

.fintupCheckboxNew.theme-new :checked + label:before,
.fintupCheckboxNew.theme-new :not(:checked) + label:before {
  border-color: #52d2bc; }

.fintupCheckboxNew.theme-new :checked + label:before {
  background: #52d2bc; }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.registerCode .inputTextLabel {
  width: 80%;
  margin: auto; }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.registerNew {
  margin-top: 20px; }
  .registerNew .headerStepsBlock img {
    width: 210px;
    height: 125px; }
  .registerNew .headerStepsBlock h4 {
    color: #00748C;
    font-size: 20px;
    font-weight: bold; }
  .registerNew .headerStepsBlock p {
    font-size: 14px;
    line-height: 1.67;
    letter-spacing: 0.32px; }
  .registerNew .headerStepsBlock .previous {
    cursor: pointer; }
  .registerNew .headerStepsBlock .breadcrumbBlock {
    margin-bottom: 20px; }
    .registerNew .headerStepsBlock .breadcrumbBlock .crumb {
      width: 150px;
      /*
        * Mobile
        */
      height: 40px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 33px; }
      @media (max-width: 767px) {
        .registerNew .headerStepsBlock .breadcrumbBlock .crumb {
          width: 90px; } }
      .registerNew .headerStepsBlock .breadcrumbBlock .crumb .icon-wrapper .fa {
        font-size: 18px; }
      .registerNew .headerStepsBlock .breadcrumbBlock .crumb .icon-wrapper, .registerNew .headerStepsBlock .breadcrumbBlock .crumb .fintupIcons {
        margin-left: 10px;
        font-weight: 300;
        /*
          * Mobile
          */ }
        @media (max-width: 767px) {
          .registerNew .headerStepsBlock .breadcrumbBlock .crumb .icon-wrapper, .registerNew .headerStepsBlock .breadcrumbBlock .crumb .fintupIcons {
            margin-left: 2px; } }
    .registerNew .headerStepsBlock .breadcrumbBlock .bread:not(.next):after {
      position: absolute;
      content: "";
      height: 2px;
      width: calc(100% - 150px);
      margin-left: calc(150px / 2);
      top: 50%;
      z-index: 100000;
      /*
          * Mobile
          */ }
      @media (max-width: 767px) {
        .registerNew .headerStepsBlock .breadcrumbBlock .bread:not(.next):after {
          width: calc(100% - 90px);
          margin-left: calc(90px / 2); } }
    .registerNew .headerStepsBlock .breadcrumbBlock .bread.previous:after {
      background: #7FB9C5; }
    .registerNew .headerStepsBlock .breadcrumbBlock .bread.previous .crumb {
      background-color: #FFFFFF;
      border: 1px solid #7FB9C5;
      color: #7FB9C5; }
    .registerNew .headerStepsBlock .breadcrumbBlock .bread.active:after {
      background: #e6e6e6; }
    .registerNew .headerStepsBlock .breadcrumbBlock .bread.active .crumb {
      background-color: #FF5200;
      border: 1px solid #FF5200;
      color: #FFFFFF;
      font-weight: bold; }
    .registerNew .headerStepsBlock .breadcrumbBlock .bread.next .crumb {
      background-color: #FFFFFF;
      margin: auto;
      border: 1px solid #e6e6e6;
      color: #e6e6e6; }
  .registerNew .registerBlock {
    margin-top: 20px; }
    .registerNew .registerBlock h3 {
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 0px; }
    .registerNew .registerBlock .formBlock .buttons {
      display: flex;
      justify-content: center;
      align-items: center; }
      .registerNew .registerBlock .formBlock .buttons button, .registerNew .registerBlock .formBlock .buttons .socialLogin {
        margin: 20px 40px 0px;
        /*
          * Mobile
          */ }
        @media (max-width: 767px) {
          .registerNew .registerBlock .formBlock .buttons button, .registerNew .registerBlock .formBlock .buttons .socialLogin {
            margin: 10px 20px 0px; } }
      .registerNew .registerBlock .formBlock .buttons .socialLogin .react-google-oauth-button-login {
        width: 150px !important;
        height: 45px;
        border-radius: 30px;
        border: 2px solid #52d2bc;
        font-weight: bold;
        text-transform: uppercase; }
        .registerNew .registerBlock .formBlock .buttons .socialLogin .react-google-oauth-button-login:hover {
          opacity: 1; }
        .registerNew .registerBlock .formBlock .buttons .socialLogin .react-google-oauth-button-login:active {
          color: inherit;
          border: 2px solid #52d2bc;
          box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
        .registerNew .registerBlock .formBlock .buttons .socialLogin .react-google-oauth-button-login .react-google-oauth-button-border {
          display: flex;
          justify-content: center;
          align-items: center; }
    .registerNew .registerBlock .formBlock .fieldsBlock {
      margin-top: 25px; }
      .registerNew .registerBlock .formBlock .fieldsBlock .buttonsFields {
        display: flex;
        justify-content: space-around;
        /*
          * Mobile
          */ }
        @media (max-width: 767px) {
          .registerNew .registerBlock .formBlock .fieldsBlock .buttonsFields {
            margin-bottom: 20px; } }
    .registerNew .registerBlock .formBlock .terms {
      width: 50%;
      text-align: left;
      margin-bottom: 20px;
      /*
        * Mobile
        */ }
      .registerNew .registerBlock .formBlock .terms .checkboxTerm {
        margin-top: 10px;
        margin-bottom: 0px; }
        .registerNew .registerBlock .formBlock .terms .checkboxTerm label {
          font-size: 14px !important; }
      @media (max-width: 767px) {
        .registerNew .registerBlock .formBlock .terms {
          width: 90%; }
          .registerNew .registerBlock .formBlock .terms .checkboxTerm {
            margin-top: 20px; } }

.suitabilitySteps{
  /* margin-left: -35px; */
  margin-bottom: 15px;
  margin-top: 20px;
}

.suitabilitySteps .suitabilityStep.notActive {
  background-color: #7fb9c5;
  color: #3f96a8;
  float: left;
  margin-left: 20px;
  padding-top: 10px;
  text-align: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-weight: 700;
}

/* .suitabilitySteps .suitabilityStep.previousStep {
  cursor: pointer;
} */

.suitabilitySteps .suitabilityStep.active {
  background-color: #fff;
  color: #3f96a8;
  float: left;
  margin-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 50px;
  font-weight: 700;
}

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.contractGeneralView {
  /*
  * Mobile
  */ }
  .contractGeneralView .multipleFormContainer {
    padding: 0;
    min-height: auto;
    border: 0;
    background-color: #FFFFFF; }
  .contractGeneralView .step-container {
    margin-top: 0px;
    border-radius: 0;
    box-shadow: none;
    border: 0; }
  .contractGeneralView .content {
    max-width: 1200px;
    width: 100%;
    margin: auto;
    padding-top: 15px !important; }
    .contractGeneralView .content .contentData {
      width: 40%;
      margin: auto;
      text-align: left; }
      .contractGeneralView .content .contentData .row {
        margin-bottom: 15px; }
        .contractGeneralView .content .contentData .row .titleLabel {
          font-size: 18px !important; }
          .contractGeneralView .content .contentData .row .titleLabel img {
            width: 13px; }
        .contractGeneralView .content .contentData .row .form-group {
          margin-top: 10px;
          font-size: 18px !important; }
        .contractGeneralView .content .contentData .row label {
          font-size: 18px !important; }
        .contractGeneralView .content .contentData .row .react-date-picker__wrapper {
          margin-top: 10px; }
        .contractGeneralView .content .contentData .row .radioGroup {
          margin-top: 15px;
          margin-bottom: 15px; }
          .contractGeneralView .content .contentData .row .radioGroup .radioButton {
            margin: 0px; }
        .contractGeneralView .content .contentData .row .beneficiarie {
          margin-top: 10px; }
          .contractGeneralView .content .contentData .row .beneficiarie .btn-trash {
            display: flex;
            justify-content: flex-end; }
    .contractGeneralView .content .buttonAttention {
      margin-top: 20px;
      margin-bottom: 20px; }
    .contractGeneralView .content .buttonsContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      margin-bottom: 10px;
      margin-left: 1%;
      margin-right: 1%; }
  @media (max-width: 575.98px) {
    .contractGeneralView .content {
      /*.miniTestTabs{
        width: 100%;
      }*/ }
      .contractGeneralView .content .contentData {
        width: 100%;
        padding-right: 30px;
        padding-left: 30px; }
    .contractGeneralView .multipleFormContainer .step-container {
      height: 100%; } }
  @media (max-width: 1250px) {
    .contractGeneralView {
      margin-top: 40px; }
      .contractGeneralView .content {
        padding-left: 0px;
        padding-right: 0px; } }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.contractTabs {
  margin-top: 20px; }
  .contractTabs .title h1 {
    margin: 0px;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 30px; }
  .contractTabs img {
    width: 210px;
    height: 125px; }
  .contractTabs h4 {
    color: #00748C;
    font-size: 20px;
    font-weight: bold; }
  .contractTabs p {
    font-size: 14px;
    line-height: 1.67;
    letter-spacing: 0.32px; }
  .contractTabs .previous {
    cursor: pointer; }
  .contractTabs .breadcrumbBlock {
    margin-top: 30px;
    margin-bottom: 30px;
    overflow: hidden; }
    .contractTabs .breadcrumbBlock .crumb {
      width: 150px;
      /*
      * Mobile
      */
      height: 40px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 33px; }
      @media (max-width: 767px) {
        .contractTabs .breadcrumbBlock .crumb {
          width: 65px; } }
      @media (max-width: 767px) {
        .contractTabs .breadcrumbBlock .crumb {
          font-size: 8px; } }
      .contractTabs .breadcrumbBlock .crumb .icon-wrapper .fa {
        font-size: 18px; }
        @media (max-width: 767px) {
          .contractTabs .breadcrumbBlock .crumb .icon-wrapper .fa {
            font-size: 11px; } }
      .contractTabs .breadcrumbBlock .crumb .icon-wrapper, .contractTabs .breadcrumbBlock .crumb .fintupIcons {
        margin-left: 10px;
        font-weight: 300;
        /*
        * Mobile
        */ }
        @media (max-width: 767px) {
          .contractTabs .breadcrumbBlock .crumb .icon-wrapper, .contractTabs .breadcrumbBlock .crumb .fintupIcons {
            margin-left: 2px;
            font-size: 9px !important; } }
    .contractTabs .breadcrumbBlock .bread:after {
      position: absolute;
      content: "";
      height: 2px;
      width: calc(100% - 150px);
      margin-left: calc(150px / 2);
      top: 50%;
      z-index: 1;
      /*
        * Mobile
        */ }
      @media (max-width: 767px) {
        .contractTabs .breadcrumbBlock .bread:after {
          width: calc(100% - 65px);
          margin-left: calc((65px + 2px) / 2); } }
    .contractTabs .breadcrumbBlock .bread.previous:after {
      background: #7FB9C5; }
    .contractTabs .breadcrumbBlock .bread.previous .crumb {
      background-color: #FFFFFF;
      border: 1px solid #7FB9C5;
      color: #7FB9C5; }
    .contractTabs .breadcrumbBlock .bread.active:after {
      background: #e6e6e6; }
    .contractTabs .breadcrumbBlock .bread.active .crumb {
      background-color: #FF5200;
      border: 1px solid #FF5200;
      color: #FFFFFF;
      font-weight: bold; }
    .contractTabs .breadcrumbBlock .bread.next:after {
      background: #e6e6e6; }
    .contractTabs .breadcrumbBlock .bread.next .crumb {
      background-color: #FFFFFF;
      margin: auto;
      border: 1px solid #e6e6e6;
      color: #e6e6e6; }
    .contractTabs .breadcrumbBlock .bread:last-child:after {
      background: transparent; }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
/**
* New styles of components
*/
*.theme-new {
  font-family: 'Lato' !important;
  font-size: 18px !important; }
  *.theme-new label, *.theme-new .form-control {
    font-family: 'Lato' !important;
    font-size: 18px !important; }
  *.theme-new .react-date-picker__wrapper {
    font-family: 'Lato';
    border: none;
    border-bottom: 2px solid #52d2bc;
    box-shadow: none;
    min-width: 180px;
    margin-bottom: 15px; }
    *.theme-new .react-date-picker__wrapper .react-date-picker__inputGroup {
      display: flex;
      justify-content: center;
      align-items: center; }
    *.theme-new .react-date-picker__wrapper input::-webkit-input-placeholder {
      font-weight: 400;
      color: #52d2bc5f; }
    *.theme-new .react-date-picker__wrapper input::placeholder, *.theme-new .react-date-picker__wrapper input::-webkit-input-placeholder {
      font-weight: 400;
      color: #52d2bc5f; }
    *.theme-new .react-date-picker__wrapper .react-date-picker__inputGroup__divider {
      font-size: 30px;
      font-weight: 500;
      color: #52d2bc;
      padding: 0px 10px; }

.titleLabel.theme-new {
  display: flex;
  align-items: center;
  font-weight: 500; }
  .titleLabel.theme-new .title {
    color: #52d2bc;
    margin-right: 10px; }
  .titleLabel.theme-new .fa {
    font-size: 18px;
    color: #52d2bc; }
  .titleLabel.theme-new .description {
    margin-left: 20px;
    color: #000000;
    font-weight: bold; }
  .titleLabel.theme-new .tooltipIcon {
    margin-left: 15px; }
  .titleLabel.theme-new.no-arrow .description {
    margin-left: 0px;
    margin-bottom: 0px; }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.fintupTooltip {
  color: #FFFFFF !important;
  background-color: #00748C !important;
  font-size: 10px;
  font-weight: bold;
  pointer-events: auto; }
  .fintupTooltip h1 {
    font-size: 10px;
    font-weight: 600;
    margin-top: 10px; }
  .fintupTooltip ul {
    font-style: italic; }
  .fintupTooltip.place-right:after {
    border-right-color: #00748C !important; }
  .fintupTooltip.place-left:after {
    border-left-color: #00748C !important; }
  .fintupTooltip.place-bottom:after {
    border-bottom-color: #00748C !important; }
  .fintupTooltip.place-top:after {
    border-top-color: #00748C !important;
    border-top-style: solid !important;
    border-top-width: 6px !important; }

span[data-tip] {
  cursor: pointer; }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
/**
* New styles of components
*/
*.theme-new {
  font-family: 'Lato' !important;
  font-size: 18px !important; }
  *.theme-new label, *.theme-new .form-control {
    font-family: 'Lato' !important;
    font-size: 18px !important; }
  *.theme-new .react-date-picker__wrapper {
    font-family: 'Lato';
    border: none;
    border-bottom: 2px solid #52d2bc;
    box-shadow: none;
    min-width: 180px;
    margin-bottom: 15px; }
    *.theme-new .react-date-picker__wrapper .react-date-picker__inputGroup {
      display: flex;
      justify-content: center;
      align-items: center; }
    *.theme-new .react-date-picker__wrapper input::-webkit-input-placeholder {
      font-weight: 400;
      color: #52d2bc5f; }
    *.theme-new .react-date-picker__wrapper input::placeholder, *.theme-new .react-date-picker__wrapper input::-webkit-input-placeholder {
      font-weight: 400;
      color: #52d2bc5f; }
    *.theme-new .react-date-picker__wrapper .react-date-picker__inputGroup__divider {
      font-size: 30px;
      font-weight: 500;
      color: #52d2bc;
      padding: 0px 10px; }

/*
* Customize style FintupSlider component
*/
.slider .fintupSlider .rangeslider-horizontal {
  height: 5px;
  background-color: #52d2bc5e; }

h4.input {
  width: 125px;
  height: 50px;
  border-radius: 15px;
  box-shadow: 2px 2px 5px 0 #e6e6e6;
  border: solid 1px #e6e6e6;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  margin: 0px auto;
  padding-right: 6px;
  font-weight: bold;
  /*
  * Currency
  */ }
  h4.input span {
    margin-left: 3px; }

.radioGroup .labelGroup{
  margin-bottom: 20px;
}

.radioGroup .labelGroupHorizontal{
  margin-bottom: 10px;
}

labelClasses

.radioGroup {
  margin-bottom: 20px;
}
@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
/**
* New styles of components
*/
*.theme-new {
  font-family: 'Lato' !important;
  font-size: 18px !important; }
  *.theme-new label, *.theme-new .form-control {
    font-family: 'Lato' !important;
    font-size: 18px !important; }
  *.theme-new .react-date-picker__wrapper {
    font-family: 'Lato';
    border: none;
    border-bottom: 2px solid #52d2bc;
    box-shadow: none;
    min-width: 180px;
    margin-bottom: 15px; }
    *.theme-new .react-date-picker__wrapper .react-date-picker__inputGroup {
      display: flex;
      justify-content: center;
      align-items: center; }
    *.theme-new .react-date-picker__wrapper input::-webkit-input-placeholder {
      font-weight: 400;
      color: #52d2bc5f; }
    *.theme-new .react-date-picker__wrapper input::placeholder, *.theme-new .react-date-picker__wrapper input::-webkit-input-placeholder {
      font-weight: 400;
      color: #52d2bc5f; }
    *.theme-new .react-date-picker__wrapper .react-date-picker__inputGroup__divider {
      font-size: 30px;
      font-weight: 500;
      color: #52d2bc;
      padding: 0px 10px; }

.selectLabel .required-label {
  color: #FB4D6D;
  font-weight: 700;
  font-size: 14px;
  margin-left: 5px; }

.selectLabel label {
  margin-bottom: 10px; }

.selectLabel label.htmlLabel {
  font-weight: inherit; }

.selectLabel .form-group.row label.rightLabel {
  margin-top: 0px; }

.selectLabel .form-group.row label.rightLabel.dividerLabel {
  border-left-color: silver;
  border-left-width: 1px;
  border-left-style: solid; }

.selectLabel .Select.is-disabled div.Select-control {
  background-color: #eee; }

.selectLabel .Select.is-focused:not(.is-open) > .Select-control {
  border-color: #00748C; }

.selectLabel .Select.has-value.Select--single > .Select-control .Select-value a.Select-value-label:hover,
.selectLabel .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:hover,
.selectLabel .Select.has-value.Select--single > .Select-control .Select-value a.Select-value-label:focus,
.selectLabel .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:focus {
  color: #00748C; }

.selectLabel.pensionPlan .Select.has-value.Select--single > .Select-control .Select-value a.Select-value-label:hover,
.selectLabel.pensionPlan .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:hover,
.selectLabel.pensionPlan .Select.has-value.Select--single > .Select-control .Select-value a.Select-value-label:focus,
.selectLabel.pensionPlan .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:focus
.selectLabel.pensionPlan .Select--multi .Select-value,
.selectLabel.pensionPlan .Select--multi a.Select-value-label,
.selectLabel.pensionPlan.innerInput .Select-arrow-zone {
  color: #3F96A8; }

.selectLabel.saving .Select.has-value.Select--single > .Select-control .Select-value a.Select-value-label:hover,
.selectLabel.saving .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:hover,
.selectLabel.saving .Select.has-value.Select--single > .Select-control .Select-value a.Select-value-label:focus,
.selectLabel.saving .Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:focus
.selectLabel.saving .Select--multi .Select-value,
.selectLabel.saving .Select--multi a.Select-value-label,
.selectLabel.saving.innerInput .Select-arrow-zone {
  color: #7FB9C5; }

.selectLabel.pensionPlan.innerInput .labelInputContainer {
  background-color: #3F96A8; }

.selectLabel.saving.innerInput .labelInputContainer {
  background-color: #7FB9C5; }

.selectLabel.pensionPlan.innerInput .Select-control {
  border-color: #3F96A8;
  border: 1px solid #3F96A8;
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075), 0 0 0px #3F96A8; }

.selectLabel.saving.innerInput .Select-control {
  border-color: #7FB9C5;
  border: 1px solid #7FB9C5;
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075), 0 0 0px #7FB9C5; }

.selectLabel .Select--multi .Select-value {
  color: #00748C; }

.selectLabel .Select--multi a.Select-value-label {
  color: #00748C; }

.selectLabel.innerInput .labelInputContainer {
  position: absolute;
  z-index: 2;
  background-color: #00748C;
  color: #FFFFFF;
  font-weight: inherit;
  top: 2px;
  left: 2px;
  text-align: center;
  padding-top: 7px;
  padding-bottom: 7px; }

.selectLabel.innerInput.innerLabelWidth90 .labelInputContainer {
  width: 90px; }

.selectLabel.innerInput.innerLabelWidth90 .Select-placeholder, .selectLabel.innerInput.innerLabelWidth90 .Select-value-label, .selectLabel.innerInput.innerLabelWidth90 .Select-input {
  padding-left: 95px; }

.selectLabel.innerInput .Select-menu-outer {
  z-index: 10; }

.selectLabel.innerInput .Select-arrow-zone {
  color: #00748C; }

.selectLabel.innerInput .Select-control {
  background-color: #FFFFFF;
  border-color: #00748C;
  border-radius: 0px;
  border: 1px solid #00748C;
  color: #000000;
  cursor: default;
  display: table;
  border-spacing: 0;
  border-collapse: separate;
  height: 36px;
  outline: none;
  overflow: hidden;
  position: relative;
  width: 100%;
  outline: 0;
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075), 0 0 0px #00748C; }

.selectLabel.theme-tools {
  font-size: 25px; }
  .selectLabel.theme-tools .Select-arrow-zone {
    color: #00748C; }
  .selectLabel.theme-tools .Select.is-focused:not(.is-open) > .Select-control {
    box-shadow: none; }
  .selectLabel.theme-tools .Select-control {
    background-color: #FFFFFF;
    border-radius: 0px;
    border: 0px solid #00748C;
    border-bottom: 2px solid #00748C;
    color: #000000;
    cursor: default;
    display: table;
    border-spacing: 0;
    border-collapse: separate;
    height: 36px;
    outline: none;
    overflow: hidden;
    position: relative;
    width: 100%;
    outline: 0; }

.selectLabel.theme-new {
  font-family: 'Lato';
  font-size: 30px; }
  .selectLabel.theme-new .Select-arrow-zone {
    color: #52d2bc; }
  .selectLabel.theme-new .Select.is-focused:not(.is-open) > .Select-control {
    box-shadow: none;
    border-color: #52d2bc; }
  .selectLabel.theme-new .Select-control {
    background-color: #FFFFFF;
    border-radius: 0px;
    border: 0px solid #52d2bc;
    border-bottom: 2px solid #52d2bc;
    color: #000000;
    cursor: default;
    display: table;
    border-spacing: 0;
    border-collapse: separate;
    outline: none;
    overflow: hidden;
    width: 100%;
    outline: 0; }
    .selectLabel.theme-new .Select-control .Select-multi-value-wrapper {
      height: 100%;
      display: block; }
    .selectLabel.theme-new .Select-control .Select-placeholder {
      color: #979797;
      font-size: 16px;
      line-height: inherit;
      padding-left: 0;
      padding-top: 5px; }
    .selectLabel.theme-new .Select-control .Select-value {
      line-height: inherit;
      padding-left: 0;
      padding-top: 5px; }
      .selectLabel.theme-new .Select-control .Select-value .Select-value-label {
        color: #000000 !important; }
    .selectLabel.theme-new .Select-control .Select-input {
      height: auto;
      padding-left: 0px; }
      .selectLabel.theme-new .Select-control .Select-input > input {
        position: relative;
        padding: 0px;
        height: auto; }
  .selectLabel.theme-new.is-error .Select-arrow-zone {
    color: #FB4D6D; }
  .selectLabel.theme-new.is-error .Select-control {
    border-bottom: 2px solid #FB4D6D;
    /*&::placeholder, &::-webkit-input-placeholder {
          color: $color-cross-element-minitest;
        }*/ }
  .selectLabel.theme-new.is-error .errorText {
    color: #FB4D6D;
    font-size: 14px;
    display: block;
    margin-top: 5px;
    text-align: left; }
  .selectLabel.theme-new .Select-menu-outer {
    border: 0;
    background-color: #FFFFFF;
    border-top-color: #e6e6e6;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    margin-top: 0px;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 100;
    -webkit-overflow-scrolling: touch; }
  .selectLabel.theme-new .Select-menu {
    max-height: 100%;
    max-height: 400px;
    overflow-y: auto;
    background: #FFFFFF; }
  .selectLabel.theme-new .Select-option {
    margin-top: 15px;
    border-radius: 20px;
    border: solid 1px #e6e6e6;
    box-sizing: border-box;
    box-shadow: 2px 2px 5px 0 #e6e6e6;
    font-weight: lighter;
    background-color: #FFFFFF;
    color: #000000;
    cursor: pointer;
    display: block;
    padding: 8px 10px;
    padding-left: 20px; }
    .selectLabel.theme-new .Select-option.is-selected, .selectLabel.theme-new .Select-option:hover {
      border: solid 2px #52d2bc;
      font-weight: 500;
      /* Fallback color for IE 8 */
      background-color: #007eff0a;
      color: #000000; }
    .selectLabel.theme-new .Select-option.is-selected {
      font-weight: bold; }
    .selectLabel.theme-new .Select-option.is-focused {
      background-color: #ebf5ff;
      /* Fallback color for IE 8 */
      background-color: #007eff0a;
      color: #000000; }
    .selectLabel.theme-new .Select-option.is-disabled {
      color: #b5b5b5;
      cursor: default; }
  .selectLabel.theme-new .Select-noresults {
    box-sizing: border-box;
    color: #000000;
    cursor: default;
    display: block;
    padding: 8px 10px; }

/*Small and down*/
@media (max-width: 767px) {
  .selectLabel.theme-tools {
    font-size: 21px; }
  .selectLabel.innerInput .labelInputContainer {
    padding-top: 8px;
    padding-bottom: 9px; } }


@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
/**
* New styles of components
*/
*.theme-new {
  font-family: 'Lato' !important;
  font-size: 18px !important; }
  *.theme-new label, *.theme-new .form-control {
    font-family: 'Lato' !important;
    font-size: 18px !important; }
  *.theme-new .react-date-picker__wrapper {
    font-family: 'Lato';
    border: none;
    border-bottom: 2px solid #52d2bc;
    box-shadow: none;
    min-width: 180px;
    margin-bottom: 15px; }
    *.theme-new .react-date-picker__wrapper .react-date-picker__inputGroup {
      display: flex;
      justify-content: center;
      align-items: center; }
    *.theme-new .react-date-picker__wrapper input::-webkit-input-placeholder {
      font-weight: 400;
      color: #52d2bc5f; }
    *.theme-new .react-date-picker__wrapper input::placeholder, *.theme-new .react-date-picker__wrapper input::-webkit-input-placeholder {
      font-weight: 400;
      color: #52d2bc5f; }
    *.theme-new .react-date-picker__wrapper .react-date-picker__inputGroup__divider {
      font-size: 30px;
      font-weight: 500;
      color: #52d2bc;
      padding: 0px 10px; }

.dynamicFormGroup .btn-trash {
  display: flex;
  justify-content: flex-end; }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.ContractStep0 .titleLabel {
  color: red !important; }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.FintupCalendly {
  text-align: center; }
  .FintupCalendly .fintupCalendly4 span {
    margin-top: 5px;
    display: block;
    font-weight: bold;
    font-size: 15px; }
  .FintupCalendly .fintupCalendly3 {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center; }
    @media (max-width: 767px) {
      .FintupCalendly .fintupCalendly3 {
        flex-direction: column;
        margin-top: 0px; } }
    .FintupCalendly .fintupCalendly3 .fintupButton {
      margin-right: 50px; }
      @media (max-width: 767px) {
        .FintupCalendly .fintupCalendly3 .fintupButton {
          margin-bottom: 20px;
          margin-right: 0px; } }
  .FintupCalendly .fintupCalendly2 h1 {
    font-size: 15px;
    font-weight: 700; }
  .FintupCalendly .fintupCalendly2 .fintupButton .btn {
    border-style: solid;
    border-width: 1px;
    border-color: #FFFFFF;
    border-radius: 20px; }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.contractExpertHelp {
  z-index: 99;
  width: 100%;
  max-width: 336px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: auto; }
  .contractExpertHelp .managerImage .imageFrame {
    height: auto;
    width: auto;
    align-items: center;
    display: flex;
    justify-content: center;
    background: transparent; }
    .contractExpertHelp .managerImage .imageFrame img.mainImage {
      width: 75px;
      height: 75px;
      border-radius: 75px;
      vertical-align: middle;
      border: 0; }
  @media (min-width: 575.99px) {
    .contractExpertHelp.isMobile {
      display: none; } }
  @media (max-width: 575.98px) {
    .contractExpertHelp.isMobile {
      margin-bottom: 15px; } }

.contractExpertHelp:not(.isMobile) {
  position: fixed;
  right: 35px;
  bottom: 60px;
  justify-content: flex-end;
  /*
  * Hide in mobile only if not .isMobile class set
  */ }
  @media (max-width: 575.98px) {
    .contractExpertHelp:not(.isMobile) {
      display: none; } }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.rtf {
  z-index: 1001 !important;
  height: 75px !important;
  width: 75px !important;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (max-width: 767px) {
    .rtf {
      height: 50px !important;
      width: 50px !important;
      margin-bottom: 0px !important;
      justify-content: flex-end; } }

.rtf--mb > * {
  -webkit-transform: none !important;
          transform: none !important;
  transition: none !important; }

.rtf--mb__c {
  padding-top: 0px !important; }
  @media (max-width: 767px) {
    .rtf--mb__c {
      padding-right: 0px !important;
      padding-bottom: 0px !important; } }

.fintupFabExpert {
  margin-bottom: -25px !important;
  background: #FFFFFF;
  z-index: 1001;
  max-width: 320px;
  position: fixed;
  bottom: 0px;
  right: 5px;
  color: #000000;
  padding: 25px;
  font-size: 13px;
  text-align: justify;
  letter-spacing: 0.4px;
  border-radius: 10px;
  border: 2px solid #52d2bc;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05); }
  @media (max-width: 767px) {
    .fintupFabExpert {
      right: -25px !important; } }
  .fintupFabExpert h1 {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px; }
  .fintupFabExpert .managerImage {
    transition: none !important; }
  .fintupFabExpert .container-options .mainImage {
    width: 50px !important;
    height: 50px !important; }
  .fintupFabExpert .container-options .header-options {
    display: flex;
    align-items: center; }
    .fintupFabExpert .container-options .header-options h3 {
      font-size: 16px;
      margin-left: 10px; }
  .fintupFabExpert .container-options .phrase {
    margin-top: 20px;
    font-weight: normal;
    font-style: italic;
    padding-bottom: 20px;
    border-bottom: 1px dotted #979797;
    white-space: pre-wrap; }
  .fintupFabExpert .container-options h3 {
    margin: 20px 0px 10px;
    font-size: 16px;
    font-weight: bold; }
  .fintupFabExpert .container-options table td {
    line-height: 35px; }
    .fintupFabExpert .container-options table td a {
      cursor: pointer;
      font-weight: normal;
      color: #000000; }
    .fintupFabExpert .container-options table td a.element {
      color: #52d2bc;
      margin-top: 3px;
      display: block; }
  .fintupFabExpert .container-options table tr:first-child a.element {
    font-weight: bold;
    font-size: 16px; }
  .fintupFabExpert .container-options table td:first-child {
    text-align: center;
    padding-right: 5px; }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/

.fintupCheckboxLabel {
  margin-bottom: 0px;
}

.checkboxGroup.horizontal div[class^="col-sm"]{
  padding-left: 0px;
}

.checkboxGroup.horizontal .fintupCheckbox:first-child { 
  margin-top: 0px;
}
@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
/**
* New styles of components
*/
*.theme-new {
  font-family: 'Lato' !important;
  font-size: 18px !important; }
  *.theme-new label, *.theme-new .form-control {
    font-family: 'Lato' !important;
    font-size: 18px !important; }
  *.theme-new .react-date-picker__wrapper {
    font-family: 'Lato';
    border: none;
    border-bottom: 2px solid #52d2bc;
    box-shadow: none;
    min-width: 180px;
    margin-bottom: 15px; }
    *.theme-new .react-date-picker__wrapper .react-date-picker__inputGroup {
      display: flex;
      justify-content: center;
      align-items: center; }
    *.theme-new .react-date-picker__wrapper input::-webkit-input-placeholder {
      font-weight: 400;
      color: #52d2bc5f; }
    *.theme-new .react-date-picker__wrapper input::placeholder, *.theme-new .react-date-picker__wrapper input::-webkit-input-placeholder {
      font-weight: 400;
      color: #52d2bc5f; }
    *.theme-new .react-date-picker__wrapper .react-date-picker__inputGroup__divider {
      font-size: 30px;
      font-weight: 500;
      color: #52d2bc;
      padding: 0px 10px; }

.document {
  margin-bottom: 15px; }
  .document .row {
    margin-left: 0px; }
  .document .documentText {
    padding-top: 0px;
    color: #979797;
    cursor: pointer;
    padding-left: 0px;
    font-weight: 700; }
  .document .fileDescription {
    font-size: 14px;
    font-weight: 500; }
  .document .documentButtonsText {
    margin-top: 5px; }
  .document.newStyle .documentFooter {
    text-align: center;
    font-size: 17px; }
    .document.newStyle .documentFooter .fintupIcons {
      margin-right: 10px; }
  .document.newStyle .documentContainer {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px; }
    .document.newStyle .documentContainer .documentInner {
      color: #7FB9C5;
      border-color: #7FB9C5;
      border-style: dotted;
      border-width: 1px;
      width: 100%;
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .document.newStyle .documentContainer .documentInner .sk-spinner {
        color: #7FB9C5;
        margin: auto; }
      .document.newStyle .documentContainer .documentInner .fintupIcons {
        margin-left: 2px;
        margin-right: 2px; }
  .document.newStyle.theme-new .documentInner {
    color: #52d2bc !important;
    border-color: #52d2bc !important;
    border-radius: 10px; }
    .document.newStyle.theme-new .documentInner .content {
      /*.icon-wrapper .fa-check{
          //color: yellow;
        }*/ }
      .document.newStyle.theme-new .documentInner .content .iconsFileUpload {
        display: flex;
        justify-content: center;
        align-items: center; }
      .document.newStyle.theme-new .documentInner .content p {
        font-size: 17px;
        font-weight: 500;
        margin-bottom: 5px; }
      .document.newStyle.theme-new .documentInner .content p.or {
        color: #52d2bc5f;
        margin-bottom: 5px; }
      .document.newStyle.theme-new .documentInner .content .icon-wrapper .fa-times {
        color: #EF6776; }
      .document.newStyle.theme-new .documentInner .content span.result {
        margin-left: 10px;
        color: #000000;
        font-weight: 300; }
      .document.newStyle.theme-new .documentInner .content .fintupButton button {
        height: 30px;
        border-radius: 8px;
        padding: 0px; }
        .document.newStyle.theme-new .documentInner .content .fintupButton button div {
          display: flex;
          justify-content: center;
          align-items: center; }
          .document.newStyle.theme-new .documentInner .content .fintupButton button div .fintupIcons {
            margin-right: 5px; }

.document .documentUpdateContainer input[type="file"] {
  display: none; }

.document a.documentLink {
  color: #8a8a8a; }

.document a.documentLink:hover {
  text-decoration: none; }

.document a.documentLink:focus {
  text-decoration: none;
  outline-color: transparent; }

/* Small devices (tablets, 768px and up) */
@media (max-width: 768px) {
  .document .fileDescription {
    margin-top: 10px; } }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.document {
  margin-bottom: 15px; }
  .document .row {
    margin-left: 0px; }
  .document .documentText {
    padding-top: 0px;
    color: #979797;
    cursor: pointer;
    padding-left: 0px;
    font-weight: 700; }
  .document .fileDescription {
    font-size: 14px;
    font-weight: 500; }
  .document .documentButtonsText {
    margin-top: 5px; }
  .document.newStyle .documentFooter {
    text-align: center;
    font-size: 18px; }
    .document.newStyle .documentFooter .fintupIcons {
      margin-right: 10px; }
  .document.newStyle .documentContainer {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px; }
    .document.newStyle .documentContainer .documentInner {
      color: #7FB9C5;
      border-color: #7FB9C5;
      border-style: dotted;
      border-width: 1px;
      width: 100%;
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .document.newStyle .documentContainer .documentInner .sk-spinner {
        color: #7FB9C5; }
      .document.newStyle .documentContainer .documentInner .fintupButton .alt {
        border-color: #7FB9C5;
        color: #7FB9C5;
        font-size: 13px;
        border-radius: 25px;
        height: inherit; }
      .document.newStyle .documentContainer .documentInner .fintupIcons {
        margin-left: 2px;
        margin-right: 2px; }

.document .documentUpdateContainer input[type="file"] {
  display: none; }

.document a.documentLink {
  color: #8a8a8a; }

.document a.documentLink:hover {
  text-decoration: none; }

.document a.documentLink:focus {
  text-decoration: none;
  outline-color: transparent; }

/* Small devices (tablets, 768px and up) */
@media (max-width: 768px) {
  .document .fileDescription {
    margin-top: 10px; } }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.cookiesAdvise {
  direction: ltr;
  color: #8a8a8a;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 99999;
  background-color: #ddd;
  margin-left: 0px;
  padding-right: 25px;
  padding-left: 25px;
  padding-top: 20px;
  padding-bottom: 20px; }

.cookiesAdvise a {
  cursor: pointer; }

.cookiesAdvise a:hover {
  color: #00748C !important; }

.cookiesAdvise a:focus {
  outline-color: transparent !important;
  text-decoration: none; }

@media (max-width: 767px) {
  .cookiesAdvise .cookiesAdviseButton {
    margin-top: 10px;
    text-align: center; } }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
#root {
  font-family: 'Lato', sans-serif;
  font-size: 13px; }

a {
  color: #52d2bc;
  font-weight: 600;
  transition: ease all .3s; }
  a:hover {
    color: #000;
    text-decoration: none; }

a:focus {
  outline: 0px auto -webkit-focus-ring-color;
  outline-offset: -2px;
  text-decoration: none; }

.newStyles.row {
  margin-left: 0px;
  margin-right: 0px; }

.newStyles .row {
  margin-left: 0px;
  margin-right: 0px; }

.newStyleGrid {
  padding-left: 0px;
  padding-right: 0px; }

.fintup.fintupHeaderContainer.fintupPrivateContainer .appContent {
  padding-top: 72px; }

.fintup.fintupHeaderContainer.fintupPrivateContainer.hasOperationBar .appContent {
  padding-bottom: 40px; }

.fintup.fintupHeaderContainer.oldForm .appContent {
  background-color: #00748C; }

/*Para alinear middle el contenido de la pantalla*/
.fintup .verticalContainer {
  display: table; }

.fintup .verticalContainer > div {
  display: table-cell;
  vertical-align: middle; }

.fintup.fintupHeaderContainer .appContent {
  min-height: calc(100vh - 72px);
  min-height: -o-calc(100vh - 72px);
  /* opera */
  /* google, safari */
  min-height: -moz-calc(100vh - 72px);
  /* firefox */
  background-color: #FFFFFF; }

.fintup.fintupHeaderContainer.fintupPrivateContainer .appContent {
  min-height: calc(100vh);
  min-height: -o-calc(100vh);
  /* opera */
  /* google, safari */
  min-height: -moz-calc(100vh);
  /* firefox */
  background-color: #FFFFFF; }

.fintup.fintupHeaderContainer .appContent .allHeight {
  min-height: calc(100vh - 120px);
  min-height: -o-calc(100vh - 120px);
  min-height: -moz-calc(100vh - 120px); }

.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none; }

.fintup.themeNew {
  position: relative;
  min-height: 100vh; }
  .fintup.themeNew .appContent {
    padding-bottom: 50px !important;
    overflow: auto; }
  .fintup.themeNew .appContent {
    min-height: auto;
    padding-bottom: 50px; }
  @media (max-width: 768px) {
    .fintup.themeNew .appContent {
      padding-bottom: 90px  !important; } }

.fintupResponsiveForm {
  background-color: #00748C; }

.container-fluid {
  padding: 0; }

.fa-1_5x {
  font-size: 1.5em; }

.fa-0_75x {
  font-size: 0.75em; }

.font-weight-bold {
  font-weight: bold; }

.font-style-italic {
  font-style: italic; }

.no-padding-horizontal {
  padding-right: 0px !important;
  padding-left: 0px !important; }

.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none; }

.bgGrey {
  background: #f5f5f5; }

.titleGrey {
  color: #7b7b7b; }

.greyColor {
  color: #8a8a8a; }

.borderTopRound {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  outline: 0; }

body {
  color: #2d2d2d; }

.center {
  text-align: center; }

.justify {
  text-align: justify; }

.right {
  text-align: right; }

.no-border {
  border-style: none !important;
  border-width: 0px !important;
  box-shadow: none !important; }

.marginTop100 {
  margin-top: 100px; }

.marginTop50 {
  margin-top: 50px; }

.marginTop30 {
  margin-top: 30px; }

.marginTop20 {
  margin-top: 20px; }

.marginTop25 {
  margin-top: 25px; }

.marginTop15 {
  margin-top: 15px; }

.marginTop10 {
  margin-top: 10px; }

.marginTopImportant100 {
  margin-top: 100px !important; }

.marginTopImportant150 {
  margin-top: 150px !important; }

.marginTopImportant170 {
  margin-top: 170px !important; }

.marginBottom10,
.marginBottom10 {
  margin-bottom: 10px; }

.marginBottom20,
.marginBottom20 {
  margin-bottom: 20px; }

.marginBottom25,
.marginBottom25 {
  margin-bottom: 25px; }

.marginRight5 {
  margin-right: 5px; }

.marginRight10 {
  margin-right: 10px; }

.marginRight20 {
  margin-right: 20px; }

.marginLeft5 {
  margin-left: 5px; }

.marginLeft10 {
  margin-left: 10px; }

.marginLeft15 {
  margin-left: 15px; }

.marginLeft20 {
  margin-left: 20px; }

.padding10 {
  padding: 10px; }

.padding15 {
  padding: 15px; }

.padding20 {
  padding: 20px; }

.padding25 {
  padding: 25px; }

.padding30 {
  padding: 30px; }

.paddingLeft10 {
  padding-left: 10px; }

.paddingLeft15 {
  padding-left: 15px; }

.paddingLeft20 {
  padding-left: 20px; }

.paddingLeft25 {
  padding-left: 25px; }

.paddingLeft30 {
  padding-left: 30px; }

.paddingRight10 {
  padding-right: 10px; }

.paddingRight15 {
  padding-right: 15px; }

.paddingRight20 {
  padding-right: 20px; }

.paddingRight25 {
  padding-right: 25px; }

.paddingRight30 {
  padding-right: 30px; }

.paddingTop0 {
  padding-top: 0px !important; }

.paddingTop5 {
  padding-top: 5px !important; }

.paddingTop15 {
  padding-top: 15px !important; }

.paddingTop30 {
  padding-top: 30px !important; }

.imageStack {
  position: relative; }

.imageStack .imageBackgroung {
  position: absolute; }

.imageStack .mainImage {
  position: absolute; }

.clearAll {
  clear: both; }

.fRight {
  float: right; }

.fLeft {
  float: left; }

.flex-wrapper {
  display: flex;
  justify-content: space-around; }

.flex-wrapper-start {
  display: flex;
  justify-content: flex-start; }

.flex-item {
  width: 100%; }

.flex-item-inherit {
  width: inherit; }

.fintup .formColumn {
  padding-right: 0px; }

.flexHorizontalCenter {
  display: flex;
  /* establish flex container */
  flex-direction: row;
  /* make main axis horizontal (default value) */
  justify-content: center;
  /* center items horizontally, in this case */
  align-items: center;
  /* center items vertically, in this case */ }

.fintupStrong {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  box-shadow: inset 0 -5px 0 0px #f363;
  border: 0;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
  box-sizing: border-box; }

.fintupStrongGreen {
  background-color: transparent !important;
  background-image: linear-gradient(to bottom, #e9fdf0, #e9fdf0);
  transition: .5s background-image linear;
  cursor: pointer; }

.fintupStrongGreen:active, .fintupStrongGreen:focus, .fintupStrongGreen:hover {
  background-color: transparent !important;
  background-image: linear-gradient(to bottom, #7dffb3, #7dffb3);
  transition: .5s background-image linear; }

/* Hide HTML5 Up and Down arrows. */
input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type="number"] {
  -moz-appearance: textfield; }

.viewContent {
  padding-left: 15px;
  padding-right: 15px; }

.rowListDetail {
  border-style: solid;
  border-width: 1px;
  border-color: #8a8a8a;
  margin-left: 0px;
  margin-bottom: 15px;
  color: #8a8a8a; }

.rowListDetailLabel {
  font-weight: 700;
  color: #8a8a8a; }

.rowListDetail .rowListDetailTitle {
  font-weight: 700; }

.noWrap {
  white-space: nowrap; }

.pensionPlanColor {
  color: #3F96A8; }

.savingColor {
  color: #7FB9C5; }

.visibility-hidden {
  visibility: hidden; }

.display-none {
  display: none; }

.margin-auto {
  margin: auto; }

.text-large {
  font-size: 18px !important; }

@media (min-width: 767px) {
  .show-xs {
    display: none !important; }
  .tableView {
    display: table;
    width: 100%; }
  .tableView > div {
    display: table-row; }
  .tableView > div > div {
    display: table-cell;
    float: none;
    vertical-align: middle; } }

/* Extra small devices (767px and down) */
@media (max-width: 767px) {
  #root {
    font-family: 'Lato', sans-serif;
    font-size: 11px; }
  .fintup.fintupFullWidget .controlIframePadding {
    padding-right: 65px !important;
    padding-left: 65px !important; }
  .fintup.fintupHeaderContainer.fintupPrivateContainer.hasOperationBar .appContent {
    padding-bottom: 20px;
    padding-top: 115px; }
  .fintup.fintupHeaderContainer.fintupPublicContainer .appContent {
    min-height: calc(100vh - 100px);
    min-height: -o-calc(100vh - 100px);
    /* opera */
    /* google, safari */
    min-height: -moz-calc(100vh - 100px);
    /* firefox */
    background-color: #FFFFFF; }
  .fintup.fintupHeaderContainer.fintupPublicContainer.cnp-app .appContent {
    min-height: calc(100vh - 160px);
    min-height: -o-calc(100vh - 160px);
    /* opera */
    /* google, safari */
    min-height: -moz-calc(100vh - 160px);
    /* firefox */
    background-color: #FFFFFF; }
  .fintup.fintupHeaderContainer .appContent .allHeight {
    min-height: calc(100vh - 160px);
    min-height: -o-calc(100vh - 160px);
    min-height: -moz-calc(100vh - 160px); }
  .fintup.fintupHeaderContainer .appContent {
    min-height: calc(100vh - 110px);
    min-height: -o-calc(100vh - 110px);
    /* opera */
    /* google, safari */
    min-height: -moz-calc(100vh - 110px);
    /* firefox */ }
  .row.vertical-align {
    display: block;
    /* Turn off the flexible box layout */ }
  .paddingBottom0-xs {
    padding-bottom: 0px !important; }
  .paddinLeft15-xs {
    padding-left: 25px; }
  .marginBottom15-xs {
    margin-bottom: 15px; }
  .marginBottom10-xs {
    margin-bottom: 10px; }
  .marginTop5-xs {
    margin-top: 5px; }
  .marginTop10-xs {
    margin-top: 10px; }
  .marginTop12-xs {
    margin-top: 12px; }
  .marginTop15-xs {
    margin-top: 15px; }
  .marginTop20-xs {
    margin-top: 20px; }
  .fintupResponsiveForm {
    background-color: #FFFFFF; }
  .tableView.mobile {
    display: table;
    width: 100%; }
  .tableView.mobile > div {
    display: table-row; }
  .tableView.mobile > div > div {
    display: table-cell;
    float: none;
    vertical-align: middle; }
  .newStyleGridMobile,
  .newStyleGridMobile > div,
  .newStyleGridMobile > div > div {
    padding-left: 0px;
    padding-right: 0px; }
  .text-left-mobile {
    text-align: left; } }

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .row.vertical-align {
    display: flex;
    align-items: center; }
  .row.flex-row {
    display: flex; } }

:focus {
  outline: -webkit-focus-ring-color auto 0px; }

.flex {
  display: flex; }

.flex-one {
  flex: 1 1; }

.flex-zero {
  flex: 0 1; }

.space-between {
  justify-content: space-between; }

.flex-start {
  justify-content: flex-start; }

.flex-end {
  justify-content: flex-end; }

.flex-center {
  justify-content: center; }

.flex-column {
  flex-direction: column; }

/*
* Notifications
*/
.notification-item .notification-close:after {
  font-size: 18px !important; }

.notification-item.notification-danger {
  border-left: none;
  background-color: #FB4D6D; }
  .notification-item.notification-danger .notification-close {
    background-color: #FB4D6D; }

.notification-item.notification-success {
  border-left: none;
  background-color: #52d2bc; }
  .notification-item.notification-success .notification-close {
    background-color: #52d2bc; }

.card-item {
  border-radius: 20px;
  box-shadow: 2px 2px 5px 0 #e6e6e6;
  border: solid 1px #e6e6e6;
  background-color: #FFFFFF;
  /*display: flex;
        justify-content: center;
        align-items: center;*/
  margin: 15px;
  padding: 30px 20px 15px; }
  .card-item .card-header {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px; }
    .card-item .card-header .title-card {
      font-size: 21px;
      font-weight: bold;
      margin-bottom: 0px; }
    .card-item .card-header .subtitle-card {
      margin-bottom: 0px; }
  .card-item .card-content {
    padding-right: 10px;
    padding-left: 10px; }
    .card-item .card-content .element-list {
      list-style-position: outside;
      list-style: none;
      padding-left: 0px;
      margin-bottom: 0px; }
      .card-item .card-content .element-list li {
        padding-top: 10px;
        font-weight: 600;
        padding-left: 22px; }
      .card-item .card-content .element-list li::before {
        content: "\2022";
        color: #52d2bc;
        font-weight: bold;
        display: inline-block;
        font-size: 25px;
        width: 1em;
        margin-left: -1em; }
    .card-item .card-content .element-indicator {
      display: flex;
      justify-content: space-around;
      align-items: center;
      background-color: #F2F2F2;
      padding: 15px;
      margin-top: 5px; }
      .card-item .card-content .element-indicator div {
        display: flex;
        justify-content: space-between;
        flex-direction: column; }
        .card-item .card-content .element-indicator div p {
          margin-bottom: 0px; }
        .card-item .card-content .element-indicator div p:first-child {
          font-weight: bold; }
        .card-item .card-content .element-indicator div .fintupIndicatorText.ok.arrow:before {
          content: url("/img/arrow-up-white-small.svg"); }
        .card-item .card-content .element-indicator div .fintupIndicatorText.no-ok.arrow:before {
          content: url("/img/arrow-down-white-small.svg"); }
    .card-item .card-content .element-graphic {
      margin-top: 30px;
      margin-bottom: 10px; }
      .card-item .card-content .element-graphic .element-graphic-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px; }
        .card-item .card-content .element-graphic .element-graphic-header .switch {
          display: flex;
          cursor: pointer; }
          .card-item .card-content .element-graphic .element-graphic-header .switch div {
            border: 1px solid #979797;
            box-sizing: border-box;
            padding: 5px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 16px 16px;
            height: 30px;
            width: 30px; }
          .card-item .card-content .element-graphic .element-graphic-header .switch div.active {
            background-color: #bfdce2;
            border-color: #3F96A8; }
          .card-item .card-content .element-graphic .element-graphic-header .switch div:first-child {
            border-top-left-radius: 100px;
            border-bottom-left-radius: 100px;
            background-image: url("/img/icono_ahorro.svg"); }
            .card-item .card-content .element-graphic .element-graphic-header .switch div:first-child.active {
              background-image: url("/img/icono_ahorro_active.svg"); }
          .card-item .card-content .element-graphic .element-graphic-header .switch div:last-child {
            border-top-right-radius: 100px;
            border-bottom-right-radius: 100px;
            background-image: url("/img/icono_inversion.svg"); }
            .card-item .card-content .element-graphic .element-graphic-header .switch div:last-child.active {
              background-image: url("/img/icono_inversion_active.svg"); }
        .card-item .card-content .element-graphic .element-graphic-header p {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 0px; }
    .card-item .card-content .buttonNewSave {
      display: flex;
      justify-content: center;
      margin-top: 15px;
      width: 100%; }
    .card-item .card-content .slider {
      padding-bottom: 20px;
      border-bottom: 1px dashed #ddd; }

.item-element {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dashed #ddd;
  font-size: 15px; }
  .item-element p {
    margin-bottom: 0px; }
  .item-element p:first-child {
    max-width: 70%; }
  .item-element p:last-child {
    font-weight: bold; }
  .item-element.border-bottom-solid {
    border-bottom: 2px solid #ddd; }

.item-buttons {
  display: flex; }
  .item-buttons .buttonOpenModal {
    display: flex;
    width: 100%;
    font-weight: bold;
    font-size: 20px;
    align-items: flex-end;
    justify-content: flex-end;
    cursor: pointer; }
    .item-buttons .buttonOpenModal img {
      cursor: pointer; }
    .item-buttons .buttonOpenModal:nth-child(2) {
      margin-left: 15px; }

.menu-product {
  margin-top: 5px; }
  .menu-product ul {
    min-width: 180px;
    position: absolute;
    right: 50%;
    background: #00748C;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    list-style: none;
    padding: 10px 20px;
    white-space: nowrap; }
    .menu-product ul li {
      cursor: pointer;
      margin: 5px;
      padding: 5px 0px;
      border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
      font-weight: bold;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      font-size: 12px; }
      .menu-product ul li span {
        margin-top: 3px; }
      .menu-product ul li img {
        margin-right: 10px; }
      .menu-product ul li:last-child {
        border-bottom: 0px; }

.fintup.safari .inputControl .form-control {
  height: 36px; }

.fintup.safari .oneMillionView .targetBlock__first {
  width: 40rem; }
  .fintup.safari .oneMillionView .targetBlock__first__blockRetirementAge {
    width: 23rem; }

.fintup.safari .oneMillionView .targetBlock__second {
  width: 42rem; }
  .fintup.safari .oneMillionView .targetBlock__second__blockRetirementAmount {
    width: 24rem; }

.fintup.ios .oneMillionView .targetBlock__first {
  width: 31rem; }

.fintup.ios .oneMillionView .targetBlock__second {
  width: 33rem; }

@-webkit-keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px); }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .modal-video-close-btn:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.copyRightNew.footer-bottom {
  position: absolute;
  bottom: 0px;
  width: 100%;
  color: #FFFFFF;
  line-height: 50px;
  text-align: center;
  background-color: #00748C; }

@media (max-width: 767px) {
  .copyRightNew.footer-bottom {
    line-height: 30px;
    background-color: transparent;
    color: #00748C;
    position: inherit; } }

@media (min-width: 768px) {
  .copyRightNew.footer-bottom .container {
    width: 100%; } }

