@keyframes modal-video {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px); }
  to {
    transform: translate(0, 0); } }

.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }

.modal-video-effect-exit {
  opacity: 0; }
  .modal-video-effect-exit .modal-video-movie-wrap {
    -webkit-transform: translate(0, 100px);
    -moz-transform: translate(0, 100px);
    -ms-transform: translate(0, 100px);
    -o-transform: translate(0, 100px);
    transform: translate(0, 100px); }

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box; }

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }
  @media (orientation: landscape) {
    .modal-video-inner {
      padding: 10px 60px;
      box-sizing: border-box; } }

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -ms-transition: -ms-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out; }
  .modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent; }
  @media (orientation: landscape) {
    .modal-video-close-btn {
      top: 0;
      right: -45px; } }
  .modal-video-close-btn:before {
    transform: rotate(45deg); }
  .modal-video-close-btn:after {
    transform: rotate(-45deg); }
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px; }

/*
* New styles
* Minitest
*/
.fintupCheckbox.titleCheckbox.checkbox label {
  font-weight: 700; }

.fintupCheckbox.titleCheckbox.checkbox {
  margin-top: 3px; }

.fintupCheckbox.checkbox label {
  padding-left: 0px; }

.fintupCheckbox.checkbox label:after,
.fintupCheckbox.radio label:after {
  content: '';
  display: table;
  clear: both; }

.fintupCheckbox.checkbox .cr,
.fintupCheckbox.radio .cr {
  position: relative;
  border: 1px solid #a9a9a9;
  border-radius: .25em;
  width: 1.3em;
  height: 1.3em;
  float: left;
  margin-right: .5em; }

.fintupCheckbox.radio .cr {
  border-radius: 50%; }

.fintupCheckbox.checkbox .cr .cr-icon,
.fintupCheckbox.radio .cr .cr-icon {
  position: absolute;
  font-size: 10px;
  line-height: 0;
  top: 50%;
  left: calc(50% - 5px); }

.fintupCheckbox.radio .cr .cr-icon {
  margin-left: 0.04em; }

.fintupCheckbox.checkbox label input[type="checkbox"],
.fintupCheckbox.radio label input[type="radio"] {
  display: none; }

.fintupCheckbox.checkbox label input[type="checkbox"] + .cr > .cr-icon,
.fintupCheckbox.radio label input[type="radio"] + .cr > .cr-icon {
  transform: scale(3) rotateZ(-20deg);
  opacity: 0;
  transition: all .3s ease-in; }

.fintupCheckbox.checkbox label input[type="checkbox"]:checked + .cr > .cr-icon,
.fintupCheckbox.radio label input[type="radio"]:checked + .cr > .cr-icon {
  transform: scale(1) rotateZ(0deg);
  opacity: 1;
  color: #52d2bc; }

.fintupCheckbox.checkbox.saving label input[type="checkbox"]:checked + .cr > .cr-icon,
.fintupCheckbox.radio.saving label input[type="radio"]:checked + .cr > .cr-icon {
  color: #7FB9C5; }

.fintupCheckbox.checkbox.saving,
.fintupCheckbox.radio.saving {
  background-color: #FFFFFF; }

.fintupCheckbox.checkbox.pensionPlan label input[type="checkbox"]:checked + .cr > .cr-icon,
.fintupCheckbox.radio.pensionPlan label input[type="radio"]:checked + .cr > .cr-icon {
  color: #3F96A8; }

.fintupCheckbox.checkbox.pensionPlan,
.fintupCheckbox.radio.pensionPlan {
  background-color: #FFFFFF; }

.fintupCheckbox.checkbox label input[type="checkbox"]:disabled + .cr,
.fintupCheckbox.radio label input[type="radio"]:disabled + .cr {
  opacity: .5; }

.fintupCheckbox.theme-new label input[type="checkbox"]:checked + span.labelText {
  font-weight: bold !important;
  color: red !important; }
